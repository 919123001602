import React, { Component } from "react";
import ImgTrapHouse from "assets/images/home/traphouse.png";
import ImgCorner from "assets/images/home/corner.png";
import ImgClandestineLab from "assets/images/home/clandestine-lab.png";

class Locations extends Component {
  render() {
    return (
      <div className="flex w-full flex-col items-center justify-center px-5 py-16 md:flex-row md:px-10 md:py-24">
        <div className="flex w-full flex-col md:flex-row mx-auto items-center justify-center gap-4 md:gap-x-4 lg:w-auto lg:items-end">
          <img
            className="w-full max-w-xs md:max-w-none md:w-1/3 lg:w-48"
            src={ImgTrapHouse}
            alt="Trap House"
            style={{ objectFit: 'contain' }}
          />
          <img
            className="w-full max-w-xs md:max-w-none md:w-1/3 lg:w-48"
            src={ImgCorner}
            alt="Corner"
            style={{ objectFit: 'contain' }}
          />
          <img
            className="w-full max-w-xs md:max-w-none md:w-1/3 lg:w-48"
            src={ImgClandestineLab}
            alt="Clandestine Lab"
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="mt-4 px-8 w-full flex flex-col gap-y-5 md:p-5 lg:w-1/2">
          <h2 className="text-3xl font-bold text-primary md:text-5xl font-Montserrat">
            Locations
          </h2>
          <p className="text-md leading-8 text-white md:text-xl xl:leading-10">
            Currently in The Hustle Universe, there are 4 hustling locations: Consignment, The Corner, Trap House, and Clandestine Lab.
          </p>
        </div>
      </div>
    );
  }
}

export default Locations;

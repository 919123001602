import React from "react";

const Endpoints = ({ list, onSubmit }) => {
  return (
    <select
      onChange={(e) => onSubmit(e.target.value)}
      className="btn btn-default custom-shadow mt-3 w-full rounded-md border-none"
    >
      {list.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Endpoints;

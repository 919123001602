import React, { useEffect, useState, useCallback } from "react";
import icon2 from "assets/images/icons/clean-cash.png";
import work_icon from "assets/images/icons/work.png";
import DirtyCashTokenIcon from "assets/images/icons/dirty-cash-token.png";
import { useDispatch, useSelector } from "react-redux";

import { refreshAll, getLocationRewards, claimRewardsNftAll, payBailAllNft, getOutOfJailNfts, nftOutOfJail, getLastActionMessage } from "BlockchainInteractionWax";

const MiddleContent = ({ ual }) => {
  const [dirty_cash_total, setDirtyCashTotal] = useState(0.0)
  const [work_total, setWorkTotal] = useState(0.0)
  const [bail_cost_total, setBailCostTotal] = useState(0.0)
  const [work_bail_cost, setTotalWorkBailCost] = useState(0.0)
  const [work_out_cost, setTotalWorkOutCost] = useState(0.0)
  const [total_out, setTotalOut] = useState(0.0)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const fetchData = async () => {
    const value = await getLocationRewards(ual, 2)
    setDirtyCashTotal(value[0])
    setWorkTotal(value[1])
    setBailCostTotal(value[2])
    if (value[3] > 0) {
      setTotalWorkBailCost(value[3])
    }
    const value1 = await getOutOfJailNfts(ual, 2)
    if (value1 !== undefined) {
      setTotalOut(value1[0])
      setTotalWorkOutCost([value1[1]])
    }
    const value7 = await getLastActionMessage(ual);
    if (value7 !== popUpMessage && popUpMessage !== "") {
      setPopUpMessage(value7)
      setIsPopupOpen(true);
    }
    else {
      setPopUpMessage(value7)
    }
  };

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  async function handleUnstake() {
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function payBailAll() {
    if (bail_cost_total <= 0) {
      await nftOutOfJail(ual, 2);
    }
    else {
      await payBailAllNft(ual, 0, 2, 1);
    }

    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function collectRewards() {
    await claimRewardsNftAll(ual, 0, 2, 1)
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch);
  }

  async function handleGetReport()
  {
    const value7 = await getLastActionMessage(ual);
    setPopUpMessage(value7)
    setIsPopupOpen(true);
  }

  return (
    <div className="order-1 mb-5 w-full md:mb-0 lg:order-2 lg:w-4/12 ">
      <div className="flex justify-center ">
        <div className="w-1/2">
          <div className="mt-5 flex items-center justify-center">
            {dirty_cash_total > 0 && (
              <div className="w-full">
                <div className="flex flex-col items-center justify-center gap-y-1">
                  <p className="text-center text-xl font-semibold text-white">
                    Collected Dirty Cash
                  </p>
                  <div className="w-10">
                    <img
                      src={DirtyCashTokenIcon}
                      alt="Hustlers Token Icon"
                      className="object- h-full w-full"
                    />
                  </div>
                  <p className="mb-2 font-bold text-white">{dirty_cash_total}</p>
                </div>

                {/* <button className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] block w-full rounded-md px-5 py-2.5" onClick={collectRewards}>
                  Collect rewards
                </button> */}
                <div className='buttonContainer '  >
                  <button className="w-full px-4 py-1" onClick={collectRewards}>Collect rewards</button>
                </div>

              </div>
            )}
          </div>
          {work_total > 0 && (
            <div className="mt-5 flex items-center justify-center">
              <div className="w-full">
                <div className="flex flex-col items-center justify-center gap-y-1">
                  <p className="text-center font-semibold text-white">
                    Work required to collect
                  </p>
                  <div className="w-10">
                    <img
                      src={work_icon}
                      alt="Hustlers Token Icon"
                      className="object- h-full w-full"
                    />
                  </div>
                  <p className="mb-2 font-bold text-white">{work_total}</p>
                </div>
              </div>
            </div>
          )}
          {bail_cost_total > 0 && (
            <div className="mt-5 flex items-center justify-center">
              <div className="w-full">
                <div className="flex flex-col items-center justify-center gap-y-1">
                  <p className="text-center text-xl font-semibold text-white">
                    Bail Cost
                  </p>
                  <div className="w-16">
                    <img
                      src={icon2}
                      alt="Clean Cash Icon"
                      className="object- h-full w-full"
                    />
                  </div>
                  <p className="mb-2 font-bold text-white">{bail_cost_total}</p>
                </div>


                <div className="flex flex-col items-center justify-center gap-y-1">
                  <p className="text-center text-xl font-semibold text-white">
                    Work Bail Cost
                  </p>
                  <div className="w-10">
                    <img
                      src={work_icon}
                      alt="Clean Cash Icon"
                      className="object- h-full w-full"
                    />
                  </div>
                  <p className="mb-2 font-bold text-white">{work_bail_cost}</p>
                </div>
                <div className='buttonContainer'  >
                  <button className="w-full" onClick={payBailAll}>Pay Bail</button>
                </div>
              </div>
            </div>
          )}
          {total_out > 0 && (
            <div className="mt-5 flex items-center justify-center">
              <div className="w-full">
                <div className="flex flex-col items-center justify-center gap-y-1">
                  <p className="text-center text-xl font-semibold text-white">
                  Total Chemists in jail
                  </p>
                  <p className="mb-2 font-bold text-white">{total_out}</p>
                  <p className="text-center text-xl font-semibold text-white">
                    Get out of jail cost
                  </p>
                  <div className="w-10">
                    <img
                      src={work_icon}
                      alt="Clean Cash Icon"
                      className="object- h-full w-full"
                    />
                  </div>
                  <p className="mb-2 font-bold text-white">{work_out_cost}</p>
                </div>
                <div className='buttonContainer'>
            <button className="w-full px-4 py-1" onClick={payBailAll}>
                  Get Out of Jail
                </button>
                </div>
              </div>
            </div>
          )}
          {isPopupOpen && (
          <div className="flex flex-col items-center w-full bg-[#1a1b1f] border border-solid justify-center border-gray-500 rounded-xl py-4 px-6"
            style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
            <p className="text-white text-md font-semibold mb-4" style={{ textAlign: 'center' }}>
              {popUpMessage}
            </p>
            <div className='buttonContainer '  >
              <button className="w-full px-4 py-1" onClick={() => setIsPopupOpen(false)}>Ok</button>
            </div>
          </div>
          )}
                    <div className="mt-5 flex items-center justify-center">
              <div className="w-full">
                <div className='buttonContainer '  >
                  <button className="w-full px-4 py-1" onClick={handleGetReport}>Get Report</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleContent;

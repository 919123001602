import React, { useState } from "react";
import DropDown from "components/shared/DropDown/DropDown";
import Menus from "./Menus";

const SafehouseMenu = () => {
  const [isShow, setIsShow] = useState(false);

  const menusList = [
    {
      id: 1,
      label: "Profile",
      url: "/profile",
    },
    {
     id: 2,
     label: "Trophies",
     url: "/challenge",
    },
    {
     id: 4,
     label: "Safe",
     url: "/safe",
    },
    {
      id: 5,
      label: "Leaderboard",
      url: "/leaderboard",
    },
    {
      id: 6,
      label: "The Trickster",
      url: "/play",
    },
    {
      id: 3,
      label: "Tutorial",
      url: "https://thehustlegame.gitbook.io/whitepaper/game-contents/tutorials",
      external: true, 
    }
  ];

  return (
    <DropDown
      isShow={isShow}
      onClose={setIsShow}
      width={'220px'}
      element={<Menus list={menusList} onSubmit={setIsShow} />}
    >
      <div type="button">
        <div
          className={`cursor-pointer bg-gradient-to-r bg-clip-text font-Montserrat font-bold capitalize tracking-wide ${
            isShow ? "text-[#e2e3e6]" : "text-[#787878]"
          }`}
          onClick={(e) => {
            e.preventDefault();
            setIsShow(true);
          }}
        >
          Safehouse
        </div>
      </div>
    </DropDown>
  );
};

export default SafehouseMenu;

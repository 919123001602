import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import { persistStore } from 'redux-persist';

const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
        // Optionally ignore state paths:
        // ignoredPaths: ['path.to.ignore', 'another.path.to.ignore'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Menus = ({ list, onSubmit }) => {
  const location = useLocation();

  const handleClick = (item, e) => {
    if (item.external) {
      e.preventDefault();
      window.open(item.url, "_blank");
    }
    onSubmit(false);
  };

  return (
    <ul className="custom-shadow bg-black py-1 text-sm md:text-sm lg:text-sm text-olive">
      {list.map((item) => (
        <li
          className="cursor-pointer bg-gradient-to-r from-blue-500 to-teal-500 text-[#787878] hover:text-[#e2e3e6] bg-clip-text font-Montserrat capitalize tracking-wide py-2 px-3"
          key={item?.id || Math.random()}
          onClick={(e) => handleClick(item, e)}
        >
          {item.external ? (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full h-full py-2 px-3"
              style={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {item.label}
            </a>
          ) : (
            <Link
              to={item.url}
              className={`${location.pathname === item.url ? "active" : "inactive"} block w-full h-full py-2 px-3`}
              style={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {item.label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Menus;
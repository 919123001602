import React, {useState, useEffect, useCallback} from "react";
import icon1 from "assets/images/gitbook.png";
import banner from "assets/images/home/banner.png";
import { registerAccount, refreshAll, getIsAccountCreated } from "BlockchainInteractionWax";
import { useDispatch, useSelector } from "react-redux";

const Hero = ({ual}) => {
  const [newUser, setNewUser] = useState(false);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();


  const fetchData = useCallback(async () => {
    if (ual) {
      var userCreated = await getIsAccountCreated(ual)
      setNewUser(userCreated)
    }

  }, [ual, store]);

  async function registerUser()
  {
    await registerAccount(ual);
    await new Promise(resolve => setTimeout(resolve, 3000));
    refreshAll(dispatch)
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div
      className="hero-section  font-Montserrat flex h-[800px] min-h-screen items-center justify-center bg-black bg-cover bg-center bg-no-repeat md:min-h-max"
      style={{
        backgroundImage: `url(${banner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="mx-3 pt-10 text-center text-white md:mx-auto md:w-7/12 ">
      {newUser === false && ual.activeUser &&(
      <div className='buttonContainer '  >
        <button className="w-full px-4 py-1" onClick={registerUser}>Register</button>
      </div>
      )}
        <h2 className="text-5xl font-bold capitalize text-primary font-Montserrat mt-3">
          The Hustle
        </h2>
        <div className="flex flex-wrap justify-center">
          <p className="mt-4 text-2xl mx-1">"The NFT game where no </p>
          <p className="mt-4 mx-1 text-2xl">dream is too big"</p>
        </div>
        <div className="mt-6">
          <p className="text-xl font-bold">Check out our whitepaper</p>
          <div className="mt-4 flex justify-center">
            <a
              href="https://thehustlegame.gitbook.io/whitepaper/"
              target={"_blank"}
              className="block h-10 w-10"
              rel="noreferrer"
            >
              <img
                src={icon1}
                alt="Whitepaper Icon"
                className="h-full w-full object-contain"
              />
            </a>
          </div>
        </div>
          <p className="mt-5 text-lg max-w-[700px] mx-auto">
            Login and experience the closest thing to being a real gang leader by
            building a crew and taking charge of one or more locations
          </p>
      </div>
    </div>
  );
};

export default Hero;

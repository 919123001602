import axios from "axios";
import { increment } from "counterSlice";

const ONE_DAY = 86400
const ONE_HOUR = 3600
const SIX_HOURS = 21600

export function refreshAll(dispatch) {
  dispatch(increment());
}

export async function buyBooster(ual, template_id) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'buyboost',
      data: {
        user: ual.activeUser.accountName,
        template_id: template_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Buy Booster failed:', e.message);
    throw e;
  }
}

export async function buyBoosterW(ual, template_id) {
  try {    
    const request = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'wuffboost',
      limit: 999999, // Set a high limit to fetch all the results
    };

    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }
      
    let cost = 12345678;
    for (const item of ownedNfts.rows) {
      if (item.template_id.toString() === template_id.toString()) {
        cost = item.boost_price;
      }
    }

    if (cost === 12345678)
      {
        return;
      }

    const htt_tokens = Number(cost);
    const assets = htt_tokens.toFixed(4) + " WUF"

    try {
      const action = {
        account: 'wuffi',
        name: 'transfer',
        data: {
          from: ual.activeUser.accountName,
          to: 'thehustlewax',
          quantity: assets,
          memo: template_id.toString()
        },
        authorization: [
          {
            actor: ual.activeUser.accountName,
            permission: ual.activeUser.requestPermission,
          },
        ],
      };

      const transaction = await ual.activeUser.signTransaction({
        actions: [action],
      }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

      console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
      } catch (e) {
        console.log('Unstaking failed:', e.message);
        throw e;
    }
  } catch (e) {
    console.log('Buy Booster failed:', e.message);
    throw e;
  }
}

export async function buyPacks(ual, template_id) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'buypack',
      data: {
        user: ual.activeUser.accountName,
        template_id: template_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Buy Pack failed:', e.message);
    throw e;
  }
}

export async function getTotalCharacterNftsLocation(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }
  else if (location === 3) {
    table = "consignment"
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var skakedcharcter = [];
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
    ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
    ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {

    for (let i = 0; i < ownedNfts.rows.length; i++) {

      skakedcharcter.push(ownedNfts.rows[i].asset_nft)

    }
    return skakedcharcter;
  }
}

export async function getNFTsInWallet(walletAddress) {
  const parsedItems = [];
  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=${walletAddress}`;
  const limit = 1000;

  async function fetchNFTs(page) {
    const response = await axios.get(`${url}&page=${page}&limit=${limit}`);
    const { data: nfts } = response.data;
    for (const item of nfts) {
      
      try {    
      if ((item.data.img === undefined && item.data.back === undefined) || item.template === null || item.template === undefined || item.template.template_id === undefined || item.template.template_id === null) {
        console.log(item)
        continue;
      }
      
      if (item.collection.collection_name !== 'thehustlewax' && !(item.template.template_id === '745101' || item.template.template_id === '805548'  || 
        item.template.template_id === '766980'  || item.template.template_id === '698098' || item.template.template_id === '801838' || item.template.template_id === '807150' ||
        item.template.template_id === '811729' || item.template.template_id === '734280'  || item.template.template_id === '708902'  || item.template.template_id === '808186'||
        item.template.template_id === '390297' || item.template.template_id === '682221'  || item.template.template_id === '682222' || item.template.template_id === '826028'))
      {
        continue;
      }
    } catch (error) {
      continue;
    }
      const parsedItem = {
        asset_id: item.asset_id,
        image: item.data.img !== undefined ? item.data.img : item.data.back,
        name: item.data.name,
        description: item.data.description,
        schema: item.schema.schema_name,
      };
      parsedItems.push(parsedItem);
    }
    if (nfts.length === limit) {
      await fetchNFTs(page + 1);
    }
  }

  await fetchNFTs(1);
  return parsedItems;
}

export async function getJackpot(ual) {

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'jackpot',
    index_position: 1,
    key_type: 'name',
    upper_bound: 'thehustlewax',
    lower_bound: 'thehustlewax',
    limit: 250
  };

  let result;
  if (ual.activeUser.client === undefined) {
    result = await ual.activeUser.rpc.get_table_rows(request);
    
  } else {
    result = await ual.activeUser.client.v1.chain.get_table_rows(request);
  }
  return[result.rows[0].wax, result.rows[0].wuff]
}

export async function getLocationStaked(ual, location) {
  var allowedTemplateIds = [];

  if (location === 0) {
    allowedTemplateIds = [645539, 645540, 645541, 645542, 645543, 645544, 645545, 645546, 799694, 799695, 799696, 799698, 820649]; 
  } else if (location === 1) {
    allowedTemplateIds = [645548, 645549, 645552, 645553, 645543, 645544, 645545, 645546, 645539, 645540, 645541, 645542];
  } else if (location === 2) {
    allowedTemplateIds = [645548, 645549, 645552, 645553];
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'staked',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 250
  };

  const ownedNfts = [];
  let more = true;
  let next_key = null;

  while (more) {
    if (next_key) {
      request.lower_bound = next_key;
    }

    let result;
    if (ual.activeUser.client === undefined) {
      result = await ual.activeUser.rpc.get_table_rows(request);
      
    } else {
      result = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
    ownedNfts.push(...result.rows);

    more = result.more;
    if (more) {
      next_key = result.next_key;
    }
  }

  const newMap = {
    rows: [],
    more: false,
    next_key: ""
  };

  newMap.rows = ownedNfts

  const filteredNfts = await Promise.all(
    newMap.rows.map(async (nft) => {
      // Fetch NFT data for each NFT
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + nft.asset_nft;
      const response = await axios.get(url);
      const nftData = response.data.data;

      // Check if the NFT satisfies the conditions
      if (location === 0) {
        if (allowedTemplateIds.includes(nft.template_id)) {
          if ([645543, 645544, 645545, 645546].includes(nft.template_id)) {
            return nftData.data.StreetLegend !== undefined ? nft : null;
          } else {
            return nft; // For other template_ids, return the NFT
          }
        }
        return null; // Exclude NFTs with other template_ids
      }
      if (location === 1) {
        if (allowedTemplateIds.includes(nft.template_id)) {
          if ([645548, 645549, 645552, 645553, 645539, 645540, 645541, 645542, 799694, 799695, 799696, 799698].includes(nft.template_id)) {
            return nftData.data.StreetLegend !== undefined ? nft : null;
          } else {
            return nft; // For other template_ids, return the NFT
          }
        }
        return null; // Exclude NFTs with other template_ids
      } else {
        return allowedTemplateIds.includes(nft.template_id) ? nft : null;
      }
    })
  );

  return filteredNfts.filter((nft) => nft !== null); // Remove null entries
}

export async function getBoosterNFTWs(ual) {
  try {

    const url = `https://wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=thehustlewax&schema_name=boosts&page=1&limit=1000&order=desc&sort=created`

    const response = await axios.get(url);
    const nfts = response.data.data;
    const allowedTemplateIds = ["645433", "645437", "645438", "645440", "645441", "798099", "798101", "798103"];
    const filteredNfts = nfts.filter(nft => allowedTemplateIds.includes(nft.template_id));

    const request = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'wuffboost',
      limit: 999999, // Set a high limit to fetch all the results
    };

    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }

    const mappedNfts = filteredNfts.map(nft => {
      return {
        template_id: nft.template_id,
        image: nft.immutable_data.img,
        name: nft.immutable_data.name,
        description: nft.immutable_data.description,
      };
    });

    const mergedData = mappedNfts.map(item => {
      const matchingItem = ownedNfts.rows.find(obj => obj.template_id.toString() === item.template_id);
      if (matchingItem) {
        return {
          ...item,
          remaining: matchingItem.remaining,
          boost_price: matchingItem.boost_price
        };
      }
      return item;
    });

    const request1 = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'playerboost',
      index_position: 1,
      key_type: 'name',
      upper_bound: ual.activeUser.accountName,
      lower_bound: ual.activeUser.accountName,
      limit: 10000
    };

    var userCooldowns
    if(ual.activeUser.client === undefined)
      {
        userCooldowns = await ual.activeUser.rpc.get_table_rows(request1);
      }
    else
      {
        userCooldowns = await ual.activeUser.client.v1.chain.get_table_rows(request1);
      }

    let userData = {};

    if (userCooldowns.rows.length > 0) {
      userData = {
        "645433": userCooldowns.rows[0].enforcer_boost,
        "645437": userCooldowns.rows[0].gang_hire_boost,
        "645438": userCooldowns.rows[0].skip_jail_time_boost,
        "645440": userCooldowns.rows[0].safe_boost,
        "645441": userCooldowns.rows[0].safe_boost_2,
        "798099": userCooldowns.rows[0].chrono_capo, 
        "798101": userCooldowns.rows[0].og_boost,
        "798103": userCooldowns.rows[0].like_a_boss,
      };
    } else {
      userData = {
        "645433": 0,
        "645437": 0,
        "645438": 0,
        "645440": 0,
        "645441": 0,
        "798099": 0,
        "798101": 0,
        "798103": 0,
      };
    }

    const updatedMergedData = mergedData.map(item => {
      const templateId = item.template_id.toString();
      const userBoost = userData[templateId];
      return {
        ...item,
        userBoost: userBoost,
      };
    });

    return updatedMergedData;
  } catch (err) {
    console.error("Failed to get NFTs in wallet", err);
  }
}

export async function getBoosterNFTs(ual) {
  try {

    const url = `https://wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=thehustlewax&schema_name=boosts&page=1&limit=1000&order=desc&sort=created`

    const response = await axios.get(url);
    const nfts = response.data.data;
    const allowedTemplateIds = ["645433", "645437", "645438", "645440", "645441", "798099", "798101", "798103"];
    const filteredNfts = nfts.filter(nft => allowedTemplateIds.includes(nft.template_id));

    const request = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'bstavailable',
      limit: 999999, // Set a high limit to fetch all the results
    };

    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }

    const mappedNfts = filteredNfts.map(nft => {
      return {
        template_id: nft.template_id,
        image: nft.immutable_data.img,
        name: nft.immutable_data.name,
        description: nft.immutable_data.description,
      };
    });

    const mergedData = mappedNfts.map(item => {
      const matchingItem = ownedNfts.rows.find(obj => obj.template_id.toString() === item.template_id);
      if (matchingItem) {
        return {
          ...item,
          remaining: matchingItem.remaining,
          boost_price: matchingItem.boost_price
        };
      }
      return item;
    });

    const request1 = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'playerboost',
      index_position: 1,
      key_type: 'name',
      upper_bound: ual.activeUser.accountName,
      lower_bound: ual.activeUser.accountName,
      limit: 10000
    };

    var userCooldowns
    if(ual.activeUser.client === undefined)
      {
        userCooldowns = await ual.activeUser.rpc.get_table_rows(request1);
      }
    else
      {
        userCooldowns = await ual.activeUser.client.v1.chain.get_table_rows(request1);
      }

    let userData = {};

    if (userCooldowns.rows.length > 0) {
      userData = {
        "645433": userCooldowns.rows[0].enforcer_boost,
        "645437": userCooldowns.rows[0].gang_hire_boost,
        "645438": userCooldowns.rows[0].skip_jail_time_boost,
        "645440": userCooldowns.rows[0].safe_boost,
        "645441": userCooldowns.rows[0].safe_boost_2,
        "798099": userCooldowns.rows[0].chrono_capo, 
        "798101": userCooldowns.rows[0].og_boost,
        "798103": userCooldowns.rows[0].like_a_boss,
      };
    } else {
      userData = {
        "645433": 0,
        "645437": 0,
        "645438": 0,
        "645440": 0,
        "645441": 0,
        "798099": 0,
        "798101": 0,
        "798103": 0,
      };
    }

    const updatedMergedData = mergedData.map(item => {
      const templateId = item.template_id.toString();
      const userBoost = userData[templateId];
      return {
        ...item,
        userBoost: userBoost,
      };
    });

    return updatedMergedData;
  } catch (err) {
    console.error("Failed to get NFTs in wallet", err);
  }
}

export async function getPacksNFT(ual) {
  try {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=thehustlewax&schema_name=packs&page=1&limit=1000&order=desc&sort=created`;

    const response = await axios.get(url);
    const nfts = response.data.data;
    const allowedTemplateIds = ["786341", "782662"];
    const filteredNfts = nfts.filter(nft => allowedTemplateIds.includes(nft.template_id));

    const request = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'pckavailable',
      limit: 999999, // Set a high limit to fetch all the results
    };

    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
        ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }

    const mappedNfts = filteredNfts.map(nft => {
      return {
        template_id: nft.template_id,
        image: nft.immutable_data.img,
        name: nft.immutable_data.name,
        description: nft.immutable_data.description,
      };
    });

    const request1 = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: 'accounts',
      index_position: 1,
      key_type: 'name',
      upper_bound: ual.activeUser.accountName,
      lower_bound: ual.activeUser.accountName,
      limit: 10000
    };

    var account
    if(ual.activeUser.client === undefined)
      {
        account = await ual.activeUser.rpc.get_table_rows(request1);
      }
    else
      {
        account = await ual.activeUser.client.v1.chain.get_table_rows(request1);
      }

    const mergedData = mappedNfts.map(item => {
      const matchingItem = ownedNfts.rows.find(obj => obj.template_id.toString() === item.template_id);
      if (matchingItem) {
        if (item.template_id === "786341") { 
          return {
            ...item,
            remaining: matchingItem.remaining,
            pack_price: matchingItem.pack_price,
            total_packs: 1000,
            g_rank: true
          };
        }
        else if (item.template_id === "647789") {
          return {
            ...item,
            remaining: matchingItem.remaining,
            pack_price: matchingItem.pack_price,
            total_packs: 10000,
            g_rank: true
          };
        }
        else if (item.template_id === "782662") {
          var g_rank = false;
          if (account.rows.length === 0) {
            g_rank = false;
          }
          else if (account.rows[0].g_level > 25) {
            g_rank = true;
          }
          return {
            ...item,
            remaining: matchingItem.remaining,
            pack_price: matchingItem.pack_price,
            total_packs: 99999,
            g_rank: g_rank
          };
        }
      }
      return item;
    });

    return mergedData;
  } catch (err) {
    console.error("Failed to get NFTs in wallet", err);
  }
}

export async function anchorUnstakeNftLocations(ual, asset_id, type, all) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'removenft',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        type: type,
        all: all,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }

}

export async function anchorUnstakeNFT(ual, asset_id) {
  let asset_ids;
  if (typeof asset_id === 'object') {
    asset_ids = asset_id.map(id => parseInt(id));
  } else {
    asset_ids = [parseInt(asset_id)];
  }
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'unstake',
      data: {
        to: ual.activeUser.accountName,
        asset_ids: asset_ids,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Unstake failed:', e.message);
    throw e;
  }

}

export async function anchorGetUserNfts(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const requestUrl = 'https://wax.api.atomicassets.io/atomicassets/v1/assets';
  const owner = ual.activeUser.accountName;

  const page = 1;
  const limit = 1000;

  const response = await fetch(`${requestUrl}?owner=${owner}&page=${page}&limit=${limit}`);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  const userAssets = data.data.map(asset => ({
    asset_id: asset.asset_id,
    img: asset.data.img
  }));
  return userAssets;
}

export async function anchorStakeNft(userAccount, assetId, ual) {
  try {
    let assetIds;
    if (typeof assetId === 'object') {
      assetIds = assetId.map(id => parseInt(id));
    } else {
      assetIds = [parseInt(assetId)];
    }

    const action = {
      account: 'atomicassets',
      name: 'transfer',
      data: {
        from: userAccount,
        to: 'thehustlewax',
        asset_ids: assetIds,
        memo: 'stake',
      },
      authorization: [
        {
          actor: userAccount,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };
    const activeUser = ual.activeUser;
    const transaction = await activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Staking failed:', e.message);
    throw e;
  }
}

export async function anchorGetStakedNfts(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'staked',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 250 // Max limit per request is 250
  };

  const userAssets = [];
  let more = true;
  let next_key = null;

  while (more) {
    if (next_key) {
      request.lower_bound = next_key;
    }

    let ownedNfts;
    if (ual.activeUser.client === undefined) {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    } else {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

    userAssets.push(...ownedNfts.rows.map(row => row.asset_nft));

    more = ownedNfts.more;
    if (more) {
      next_key = ownedNfts.next_key;
    }
  }

  return userAssets;
}

export async function anchorGetDataFromAssetIdSafe(data, ual) {
  const parsedItems = [];

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'safenfts',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let userData;

  try {
    if(ual.activeUser.client === undefined)
      {
        userData = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        userData = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }
  } catch (error) {
    // Handle the error, log it, or return an empty array as needed
    console.error('Error fetching userData:', error);
    return [];
  }

  if (!userData || !userData.rows) {
    // Handle the case where userData is undefined or has no rows
    console.error('No userData or rows found');
    return [];
  }

  // Extract all asset_nft values from userData.rows
  const existingAssetNFTs = new Set(userData.rows.map(item => item.asset_nft));

  for (const item of data) {
    if (existingAssetNFTs.has(item)) {
      continue;  // Skip this iteration if the asset_nft is already in userData
    }
    
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      description: nft.data.description,
      template_id: nft.template.template_id,
      schema: nft.schema.schema_name,
      maintenance: nft.data.maintenance
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetDataFromAssetId(data) {
  const parsedItems = [];

  for (const item of data) {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img !== undefined ? nft.data.img : nft.data.back,
      name: nft.data.name,
      description: nft.data.description,
      template_id: nft.template.template_id,
      schema: nft.schema.schema_name
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetLaunderedInfo(ual) {
  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'laundering',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }
  //var cooldown_value = 0;

  if (userData.rows.length === 0) {
    return 0;
  }

  // if (userData.rows[0].immunity_pass_cd > Math.floor(Date.now() / 1000)) {
  //   cooldown_value = userData.rows[0].immunity_pass_cd - Math.floor(Date.now() / 1000)
  // }

  const parsedItem = {
    staked_amount: userData.rows[0].staked_amount,
    staked_time: userData.rows[0].staked_time,
    unstaked_time: userData.rows[0].unstaked_time,
    last_staked_time: userData.rows[0].initial_staked_time,
    amount_to_unstake: userData.rows[0].amount_to_unstake,
    total_staked: userData.rows[0].total_staked,
    total_laundered: userData.rows[0].total_laundered,
    total_burned: userData.rows[0].total_burned
  };
  return parsedItem;
}

export async function claimTrophy(ual, id)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'claimtrophy',
      data: {
        user: ual.activeUser.accountName,
        mission_id: id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Trophy failed:', e.message);
    throw e;
  }
}

export async function anchorCollectLaundering(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'claimdirty',
      data: {
        user: ual.activeUser.accountName,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function checkInBlockchain(ual)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'checkin',
      data: {
        user: ual.activeUser.accountName
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Checkin failed:', e.message);
    throw e;
  }
}

export async function setUsername(ual, userName) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'setgangname',
      data: {
        user: ual.activeUser.accountName,
        gang_name: userName
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function anchorCollectUnstaked(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'getunstdirty',
      data: {
        user: ual.activeUser.accountName,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function anchorAddAvatar(ual, avatar_img) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addavatar',
      data: {
        user: ual.activeUser.accountName,
        image_id: avatar_img
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function anchorStakeDirtyLaundering(ual, amount) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'stakedirtycs',
      data: {
        user: ual.activeUser.accountName,
        value: amount
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function anchorUnstakeDirtyLaundering(ual, amount) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'unstakedirty',
      data: {
        user: ual.activeUser.accountName,
        value: amount
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Claiming Laundering failed:', e.message);
    throw e;
  }
}

export async function anchorGetImageId(ual) {
  if (!ual.activeUser) {
    return [""]
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'avatar',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var image_id
  if(ual.activeUser.client === undefined)
    {
      image_id = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      image_id = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (image_id.rows.length === 0) {
    return "";
  }
  else {
    return image_id.rows[0].image_id
  }
}

export async function anchorGetDataFromImmunityPass(data, ual) {
  const parsedItems = [];
  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000
  };

  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }

  var cooldown_value = 0;

  if (userData.rows[0].immunity_pass_cd > Math.floor(Date.now() / 1000)) {
    cooldown_value = userData.rows[0].immunity_pass_cd - Math.floor(Date.now() / 1000)
  }

  for (const item of data) {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);

    const nft = response.data.data;
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: cooldown_value
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetDataFromAssetIdJailPass(data, ual, location) {
  if (!ual.activeUser) {
    return []
  }
  const parsedItems = [];

  var table = 'corner'
  if (location === 0) {
    table = 'corner'
  }
  else if (location === 1) {
    table = 'traphouse'
  }
  else if (location === 2) {
    table = 'lab'
  }
  else if (location === 3) {
    table = 'consignmentl'
  }

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }

  for (const item of data) {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    var value = userData.rows[0].get_out_of_jail - Math.floor(Date.now() / 1000);
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: value > 1 ? value : 0
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetDataFromAssetIdBooster(data, ual) {
  if (!ual.activeUser) {
    return []
  }
  const parsedItems = [];
  const templateIds = ["645433", "645437", "645438", "645439", "798099", "798101", "798103"];

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignmentl',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000
  };

  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }

  for (const item of data) {
    var value = 0;
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    if (templateIds.includes(nft.template.template_id)) {
      if (nft.template.template_id === "645433") //enforcer
      {
        value = userData.rows[0].enforcer_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645437") //gang hire
      {
        value = userData.rows[0].gang_hire_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645438") //SKip Jail
      {
        value = userData.rows[0].skip_jail_time_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645439") //production
      {
        value = userData.rows[0].production_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "798099") //production
      {
        value = userData.rows[0].chrono_capo_booster_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "798103") //production
      {
        value = userData.rows[0].like_a_boss_booster_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "798101") //production
      {
        value = userData.rows[0].og_booster_cd - Math.floor(Date.now() / 1000);
      }
    }
    
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: value > 1 ? value : 0
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function getBoosterForSafe(data, ual) {
  const parsedItems = [];
  const templateIds = ["645445", "645442", "645441", "645440"];

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'safe',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let userData
  if(ual.activeUser.client === undefined)
  {
    userData = await ual.activeUser.rpc.get_table_rows(request1);
  }
else
  {
    userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
  }

  for (const item of data) {
    var value = 0;
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    if (templateIds.includes(nft.template.template_id)) {
      if (nft.template.template_id === "645440") //safe boost
      {
        value = userData.rows[0].safe_small_boost_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645441") //safe mid
      {
        value = userData.rows[0].safe_medium_boost_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645442") //safe mega
      {
        value = userData.rows[0].safe_large_boost_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645445") //safe reset
      {
        value = userData.rows[0].safe_reset_cd - Math.floor(Date.now() / 1000);
      }
    }
    
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: value > 1 ? value : 0
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetDataFromAssetIdBoosterLocation(data, ual, location) {
  const parsedItems = [];
  const templateIds = ["645433", "645437", "645438", "645439", "798101", "798103"];

  var table = 'corner'
  if(location === 0)
    {
      table = 'corner'
    }
    else if(location ===1 )
    {
      table = 'traphouse'
    }
    else if(location === 2)
    {
      table = 'lab'
    }

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }


  for (const item of data) {
    var value = 0;
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item;
    const response = await axios.get(url);
    const nft = response.data.data;
    if (templateIds.includes(nft.template.template_id)) {
      if (nft.template.template_id === "645433") //enforcer
      {
        value = userData.rows[0].enforcer_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645437") //gang hire
      {
        value = userData.rows[0].gang_hire_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645438") //SKip Jail
      {
        value = userData.rows[0].skip_jail_time_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "645439") //production
      {
        value = userData.rows[0].production_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "798103") //production
      {
        value = userData.rows[0].like_a_boss_booster_cd - Math.floor(Date.now() / 1000);
      }
      else if (nft.template.template_id === "798101") //production
      {
        value = userData.rows[0].og_booster_cd - Math.floor(Date.now() / 1000);
      }
    }
    
    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: value > 1 ? value : 0
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems
}

export async function anchorGetDataFromAssetIdNftLocation(data, ual, location) {
  const parsedItems = [];

  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'cornernft'
  } else if (location === 1) {
    table = 'traphousenft'
  } else if (location === 2) {
    table = 'labnft'
  }
  else if (location === 3) {
    table = "consignment"
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let userData;

  try {
    if(ual.activeUser.client === undefined)
      {
        userData = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        userData = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }
  } catch (error) {
    // Handle the error, log it, or return an empty array as needed
    console.error('Error fetching userData:', error);
    return [];
  }

  if (!userData || !userData.rows) {
    // Handle the case where userData is undefined or has no rows
    console.error('No userData or rows found');
    return [];
  }

  // Extract all asset_nft values from userData.rows
  const existingAssetNFTs = new Set(userData.rows.map(item => item.asset_nft));

  for (const item of data) {
    if (existingAssetNFTs.has(item.asset_nft)) {
      continue;  // Skip this iteration if the asset_nft is already in userData
    }

    var value = 0;
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + item.asset_nft;
    const response = await axios.get(url);
    const nft = response.data.data;

    var shoot_out = -1;
    var stop_search = -1;
    var level = 0;

    if (nft.data.shootout !== undefined && nft.data.stopsearch !== undefined) {    
      shoot_out = nft.data.shootout;
      stop_search = nft.data.stopsearch;
    }

    if(nft.data.level !== undefined)
    {
      level = nft.data.level
    }

    const parsedItem = {
      asset_id: nft.asset_id,
      image: nft.data.img,
      name: nft.data.name,
      template_id: nft.template.template_id,
      cooldown: value > 1 ? value : 0, 
      rarity: nft.data.rarity,
      level: level,
      stop_search:stop_search,
      shoot_out:shoot_out
    };

    // add the new object to the parsedItems array
    parsedItems.push(parsedItem);
  }
  return parsedItems;
}

export async function isConsignmentStaked(ual) {
  if (!ual.activeUser) {
    return ["", ""]
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.rows.length === 0) {
    return ["", ""];
  }
  else {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].asset_nft;
    const response = await axios.get(url);
    const nft = response.data.data;

    return ["https://ipfs.io/ipfs/" + (nft.data.img !== undefined ? nft.data.img : nft.data.back), nft.asset_id];
  }
}

export async function isJailed(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  else {
    return ownedNfts.rows[0].is_jailed;

  }
}
export async function Laundering_rank(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  else {
    return ownedNfts.rows[0].is_jailed;

  }
}

export async function isJailedTime(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  else {
    if (ownedNfts.rows[0].jail_until < (Date.now() / 1000)) {
      return 1;  //If should get out of jail
    }
    else
      return 0; //If should not get out of jail

  }
}

export async function getTimeLeftInJail(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  else {
    if (ownedNfts.rows[0].jail_until < Date.now() / 1000) {
      return 0;
    }
    else
      return Math.floor(ownedNfts.rows[0].jail_until - Date.now() / 1000);

  }
}

export async function anchorGetWorldStats(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'worldstats',
    index_position: 1,
    key_type: 'i32',
    upper_bound: 1,
    lower_bound: 1,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  const parsedItem = {
    compromised_drops: ownedNfts.rows[0].compromised_drops,
    drug_deals: ownedNfts.rows[0].drug_deals,
    intercepted_drops: ownedNfts.rows[0].intercepted_drops,
    police_shootouts: ownedNfts.rows[0].police_shootouts,
    stop_search: ownedNfts.rows[0].stop_search,
    successful_drops: ownedNfts.rows[0].successful_drops,
    total_arrests: ownedNfts.rows[0].total_arrests
  };
  return parsedItem
}

export async function anchorGetWorldStatsP(ual)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'worldstatsp',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  const parsedItem = {
    compromised_dropsp: ownedNfts.rows[0].compromised_drops,
    drug_dealsp: ownedNfts.rows[0].drug_deals,
    intercepted_dropsp: ownedNfts.rows[0].intercepted_drops,
    police_shootoutsp: ownedNfts.rows[0].police_shootouts,
    stop_searchp: ownedNfts.rows[0].stop_search,
    successful_dropsp: ownedNfts.rows[0].successful_drops,
    total_arrestsp: ownedNfts.rows[0].total_arrests
  };
  return parsedItem
}

export async function getTimeToUpgrade(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'staked',
    index_position: 1,
    key_type: 'i64',
    upper_bound: asset_id,
    lower_bound: asset_id,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  if (ownedNfts.rows[0].cooldown > Date.now() / 1000) {

    return ownedNfts.rows[0].cooldown - Math.floor(Date.now() / 1000);
  }
  else {
    return 0;
  }
}

export async function getTimeToClaim(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return 0;
  }
  else {
    if (ownedNfts.rows[0].last_claimed > Date.now() / 1000) {
      return ownedNfts.rows[0].last_claimed - Math.floor(Date.now() / 1000);
    }
    else {
      return 0;
    }

  }
}

export async function mineConsignment(ual, asset_id) {

  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'mineconsign',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function getOutOfJailPass(ual, asset_id, booster_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'postbail',
      data: {
        user: ual.activeUser.accountName,
        transporter_id: asset_id,
        booster_id: booster_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function stakeOutOfJailPassNft(ual, booster_id, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'postbailnft',
      data: {
        user: ual.activeUser.accountName,
        booster_id: booster_id,
        location: location,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}


export async function skipUpgradeNftLocations(ual, asset_id, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'bypassnft',      
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        location: location,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function upgradeNftLocations(ual, asset_id, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdnft',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        location: location,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function getIfWorkingConsignment(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignment',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return -1;
  }
  else {
    return ownedNfts.rows[0].is_claimed;

  }
}

export async function getLastActionMessage(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'lastaction',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var lastAction
  if(ual.activeUser.client === undefined)
    {
      lastAction = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      lastAction = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (lastAction.rows.length === 0) {
    return -1;
  }
  else {
    return lastAction.rows[0].result;

  }
}

export async function getOutOfJail(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'getoutjail',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function getConsignmentRewards(assetId, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + assetId;
  const response = await axios.get(url);
  const nft = response.data.data;

  let level = 0;
  if (nft.data !== undefined && nft.data.level !== undefined) {

    level = nft.data.level;
  }
  else {
    level = 5;
  }

  if( nft.template !== undefined && nft.template.template_id === '826028')
  {
    level += 10;
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'ghostlvl',
    index_position: 1,
    key_type: 'i32',
    upper_bound: parseInt(level),
    lower_bound: parseInt(level),
    limit: 10000
  };

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignmentl',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var returnValue = 1;
  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }
    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
        ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }
  if (ownedNfts.rows.length !== 0) {
    if (userData.rows[0].production_booster > 0) {
      returnValue += 0.2;
    }
    if (userData.rows[0].gang_hire_booster > 0) {
      returnValue += 0.2;
    }
    let time = 3600;
    if (userData.rows[0].chrono_capo_booster > 0) {
      time = 3600*4;
    }
    return (returnValue * time * ownedNfts.rows[0].dirty_cash_s).toFixed(3);
  }
  else
    return 0;
}

export async function getTotalJailNftsLocation(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var total = 0;
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {

    for (let i = 0; i < ownedNfts.rows.length; i++) {


      if (ownedNfts.rows[i].is_jailed === 1) {
        total++
      }
    }
    return total
  }
}

export async function getOutOfJailNfts(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const current_time = Math.floor(Date.now() / 1000)

  var table = 'corner'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var total = 0;
  var work_bail_cost = 0.0
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {

    for (let i = 0; i < ownedNfts.rows.length; i++) {
      var time_elapsed = current_time - ownedNfts.rows[i].last_claimed
      if (time_elapsed > ONE_DAY) {
        time_elapsed = ONE_DAY
      }
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[i].asset_nft;
      const response = await axios.get(url);
      const nft = response.data.data;
      var level = 0;

      if (nft.data !== undefined && nft.data.level !== undefined) {
        level = nft.data.level;
      }

      const request1 = {
        json: true,
        code: 'thehustlewax',
        scope: 'thehustlewax',
        table: 'gangstats',
        index_position: 1,
        key_type: 'i32',
        upper_bound: response.data.data.template.template_id,
        lower_bound: response.data.data.template.template_id,
        limit: 10000
      };

      var template_data
      if(ual.activeUser.client === undefined)
        {
          template_data = await ual.activeUser.rpc.get_table_rows(request1);
        }
      else
        {
          template_data = await ual.activeUser.client.v1.chain.get_table_rows(request1);
        }

      if (ownedNfts.rows[i].is_jailed === 1 && ownedNfts.rows[i].jail_until < current_time) {
        const work_s = (parseFloat(template_data.rows[0].work_consumption) + parseFloat(template_data.rows[0].work_consumption) * parseFloat(level) * 0.1) * 0.5
        const seconds = (parseFloat(template_data.rows[0].jail_sentence) + parseFloat(level)) * parseFloat(ONE_HOUR)
        work_bail_cost += parseFloat(work_s) * parseFloat(seconds)
        total++;
        continue;
      }
    }
    return [total, work_bail_cost]
  }
  return [0, 0]
}

export async function getLocationRewards(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'cornernft'
  var table2 = 'corner'
  if (location === 0) {
    table = 'cornernft'
    table2 = 'corner'
  }
  else if (location === 1) {
    table = 'traphousenft'
    table2 = 'traphouse'
  }
  else if (location === 2) {
    table = 'labnft'
    table2 = 'lab'
  }

  const current_time = Math.floor(Date.now() / 1000)

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var dirty_cash_total = 0.0
  var work_total = 0.0
  var total_bail_cost = 0.0
  var work_bail_cost = 0.0
    var ownedNfts
    if(ual.activeUser.client === undefined)
      {
        ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }
  if (ownedNfts.rows.length !== 0) {

    for (let i = 0; i < ownedNfts.rows.length; i++) {
      var time_elapsed = current_time - ownedNfts.rows[i].last_claimed
      if (time_elapsed > SIX_HOURS) {
        time_elapsed = SIX_HOURS
      }
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[i].asset_nft;
      const response = await axios.get(url);
      const nft = response.data.data;
      var level = 0;

      if (nft.data !== undefined && nft.data.level !== undefined) {
        level = nft.data.level;
      }

      const request3 = {
        json: true,
        code: 'thehustlewax',
        scope: 'thehustlewax',
        table: 'staked',
        index_position: 1,
        key_type: 'i64',
        upper_bound: parseInt(ownedNfts.rows[i].asset_nft),
        lower_bound: parseInt(ownedNfts.rows[i].asset_nft),
        limit: 1000
      };

      var stakedNfts
      if(ual.activeUser.client === undefined)
        {
          stakedNfts = await ual.activeUser.rpc.get_table_rows(request3);
        }
      else
        {
          stakedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request3);
        }
      if (stakedNfts.rows[0].cooldown > current_time) {
        continue;
      }

      const request1 = {
        json: true,
        code: 'thehustlewax',
        scope: 'thehustlewax',
        table: 'gangstats',
        index_position: 1,
        key_type: 'i32',
        upper_bound: response.data.data.template.template_id,
        lower_bound: response.data.data.template.template_id,
        limit: 10000
      };

      var template_data
      if(ual.activeUser.client === undefined)
        {
          template_data = await ual.activeUser.rpc.get_table_rows(request1);
        }
      else
        {
          template_data = await ual.activeUser.client.v1.chain.get_table_rows(request1);
        }

      if(ownedNfts.rows[i].is_jailed === 1 && ownedNfts.rows[i].jail_until > current_time)
      {
        total_bail_cost += (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability)*parseFloat(level)*0.1) * ONE_HOUR * (parseFloat(template_data.rows[0].jail_sentence) + parseFloat(level))/4
        const work_s = (parseFloat(template_data.rows[0].work_consumption) + parseFloat(template_data.rows[0].work_consumption)*parseFloat(level)*0.1)*0.5
        const seconds = (parseFloat(template_data.rows[0].jail_sentence) + parseFloat(level) ) * parseFloat(ONE_HOUR)
        work_bail_cost += parseFloat(work_s) * (parseFloat(seconds) - (parseFloat(ownedNfts.rows[i].jail_until) - parseFloat(current_time))) > 0 ?
                            parseFloat(work_s) * (parseFloat(seconds) - (parseFloat(ownedNfts.rows[i].jail_until) - parseFloat(current_time))) : 0
        continue;
      }
      
      if (template_data.rows.length !== 0 && ownedNfts.rows[i].is_jailed !== 1) {
        dirty_cash_total += (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * time_elapsed
        work_total += (parseFloat(template_data.rows[0].work_consumption) + parseFloat(template_data.rows[0].work_consumption*level*0.1)) * time_elapsed
      }
    }    
  }

  const request2 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table2,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var userData
  if(ual.activeUser.client === undefined)
    {
      userData = await ual.activeUser.rpc.get_table_rows(request2);
    }
  else
    {
      userData = await ual.activeUser.client.v1.chain.get_table_rows(request2);
    }
  var returnValue = 1.0;
  if (userData.rows[0].production_booster > 0) {
    returnValue += 0.2;
  }
  if (userData.rows[0].gang_hire_booster > 0) {
    returnValue += 0.1;
  }
  dirty_cash_total *= returnValue
  return [dirty_cash_total.toFixed(3), work_total.toFixed(3), total_bail_cost.toFixed(3), work_bail_cost.toFixed(3)]
}

export async function getConsignmentBailCost(assetId, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + assetId;
  const response = await axios.get(url);
  const nft = response.data.data;

  let level = 0;
  if (nft.data !== undefined && nft.data.level !== undefined) {

    level = nft.data.level;
  }
  else {
    level = 5;
  }

  if(nft.template !== undefined && nft.template.template_id === '826028') 
  {
    level += 10;
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'ghostlvl',
    index_position: 1,
    key_type: 'i32',
    upper_bound: parseInt(level),
    lower_bound: parseInt(level),
    limit: 10000
  };
  var returnValue = 0;
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {
    returnValue = parseFloat(ownedNfts.rows[0].bail_cost).toFixed(2);
  }
  return returnValue;
}

export async function getConsignmentStats(ual, assetId) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + assetId;
  const response = await axios.get(url);
  const nft = response.data.data;

  let level = 0;

  if(nft.collection.collection_name !== 'thehustlewax')
    {
      level = 5;
    }
  else
  {
    level = nft.data.level;
  }

  if(nft.template.template_id === '826028')
  {
    level += 10;
  }

  console.log(level)

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'ghostlvl',
    index_position: 1,
    key_type: 'i32',
    upper_bound: parseInt(level),
    lower_bound: parseInt(level),
    limit: 10000
  };
  var returnValue = 0;

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.rows[0] !== undefined) {
    return (parseFloat(ownedNfts.rows[0].dirty_cash_s) * 3600).toFixed(2);
  }
  return returnValue;
}

export async function gambleWaxBlockchain(ual)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const assets = "5.00000000 WAX"

  try {
    const action = {
      account: 'eosio.token',
      name: 'transfer',
      data: {
        from: ual.activeUser.accountName,
        to: 'thehustlewax',
        quantity: assets,
        memo: "play"
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function gambleWUffBlockchain(ual)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const assets = "200000.0000 WUF"

  try {
    const action = {
      account: 'wuffi',
      name: 'transfer',
      data: {
        from: ual.activeUser.accountName,
        to: 'thehustlewax',
        quantity: assets,
        memo: "1"
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function gambleLucky(ual, sliderValue)
{
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  let htt_tokens = Number(0.0085*Number(sliderValue));
  const assets = [htt_tokens.toFixed(4) + " HTT"]

  try {
    const action = {
      account: 'hustletokens',
      name: 'transfer1',
      data: {
        from: ual.activeUser.accountName,
        to: 'thehustlewax',
        quantity: assets,
        memo: sliderValue.toString()
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function payBailAllNft(ual, asset_id, location, all) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'paybailnft',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        location: location,
        all: all,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function nftOutOfJail(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'nftoutjail',
      data: {
        user: ual.activeUser.accountName,
        location: location,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function collectConsignment(assetId, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'claimconsign',
      data: {
        user: ual.activeUser.accountName,
        asset_id: assetId,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function claimRewardsNftAll(ual, asset_id, type, all) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'claimrewards',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        type: type,
        all: all
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function payBail(assetId, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'paybail',
      data: {
        user: ual.activeUser.accountName,
        asset_id: assetId,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}

export async function reUp(ual, value) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'reup',
      data: {
        user: ual.activeUser.accountName,
        dirty_cash: value,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action], 
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Reup failed:', e.message);
    throw e;
  }
}

// function calculateExpirationDate() {
//   const expiration = new Date(); // Gets the current date and time
//   expiration.setMinutes(expiration.getMinutes() + 30); // Adds 30 minutes to the current time
//   return expiration.toISOString().split('.')[0]; // Formats the expiration date as a simplified ISO string
// }

export async function anchortGetNftInformation(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + asset_id;
  const response = await axios.get(url);
  const nft = response.data.data;
  const data = []

  data.push({ name: nft.name, level: nft.data.level, rarity: nft.data.rarity, type: nft.data.type, edition: nft.data.edition });
  return data;

}

export async function unstakeConsignment(assetId, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'rmvtransport',
      data: {
        user: ual.activeUser.accountName,
        asset_id: assetId
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Unstaking failed:', e.message);
    throw e;
  }
}

export async function anchorAddBoostToConsignment(asset_id, ual) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addconsignb',
      data: {
        user: ual.activeUser.accountName,
        booster_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding booster to consignment failed:', e.message);
    throw e;
  }
}

export async function anchorAddBoostToHustle(asset_id, ual, location) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addbooster',
      data: {
        user: ual.activeUser.accountName,
        booster_id: asset_id,
        type: location
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding booster to consignment failed:', e.message);
    throw e;
  }
}

export async function anchorAddNftToHustle(asset_id, ual, location) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addnft',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
        type: location
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding the nft failed:', e.message);
    throw e;
  }
}

export async function anchorAddToConsignment(asset_id, ual) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addtransprt',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function addSafeToLocation(asset_id, ual, slot) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addsafe',
      data: {
        user: ual.activeUser.accountName,
        safe_id: asset_id,
        slot: slot
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    }; 

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}


export async function claimSafe(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'claimsafe',
      data: {
        user: ual.activeUser.accountName,
        asset_ids: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };    

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function performSafeMaintenance(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'performmaint',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    }; 

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function addBoostToSafe(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addsafeb',
      data: {
        user: ual.activeUser.accountName,
        booster_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    }; 

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function unlockSecondSafe(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdsslots',
      data: {
        user: ual.activeUser.accountName,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    }; 

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function startMiningSafe(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'startsafe',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}


export async function setNextPhase(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'nextphase',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    }; 

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function unstakeSafe(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'rmvsafe',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Adding to consignment failed:', e.message);
    throw e;
  }
}

export async function anchorGetBalance(ual) {
  if (!ual.activeUser || ual === undefined) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
    ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
    ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  var x = [0, 0, 0, 0, 0]
  if (ownedNfts.rows.length === 0) {
    return x;
  }
  else {
    return ownedNfts.rows[0].balances;
  }
}

export async function getIsAccountCreated(ual) {
  if (!ual.activeUser || ual === undefined) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
    ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
    ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

    if (ownedNfts.rows.length === 0) {
      return false;
    }
    return true;
  }

export async function registerAccount(ual)
{
  try {
    const action = {
      account: 'thehustlewax',
      name: 'populate',
      data: {
        user: ual.activeUser.accountName,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };
    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Populating tables failed:', e.message);
    throw e;
  }
}

export async function anchorGetExchangeCd(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  const current_time = Math.floor(Date.now() / 1000)
  if(current_time > account.rows[0].exchange_cc_ht_cd)
  {
    return 0;
  }
  else
  {
    return account.rows[0].exchange_cc_ht_cd - current_time
  }  
}

export async function anchorGetStakedLaundering(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'laundering',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.length !== 0) {
    return ownedNfts.rows[0].staked_amount;
  }
}

export async function anchorGetConsignmentSlots(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignmentl',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {
    return account.rows[0].boost_slots;
  }
}

export async function anchorGetLocationBoosterSlots(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'corner'
  }
  else if (location === 1) {
    table = 'traphouse'
  }
  else if (location === 2) {
    table = 'lab'
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {
    return account.rows[0].boost_slots;
  }
}

export async function anchorGetLocationCrewSlots(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'corner'
  }
  else if (location === 1) {
    table = 'traphouse'
  }
  else if (location === 2) {
    table = 'lab'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {
    return account.rows[0].nft_slots;
  }
}

export async function anchorGetTrophiesData(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {

    return account.rows[0]
  }
  else
  {
    return 0;
  }
}

export async function getSlotsSafe(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'safe',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let account
  if(ual.activeUser.client === undefined)
  {
    account = await ual.activeUser.rpc.get_table_rows(request);
  }
else
  {
    account = await ual.activeUser.client.v1.chain.get_table_rows(request);
  }

  if (account.rows.length !== 0) {

    return account.rows[0].safe_slots;
  }
  else {
    return 0;
  }
}

export async function anchorGetGRank(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {

    return account.rows[0].g_level;
  }
  else {
    return 0;
  }
}
export async function anchorGetMPower(ual, account_name, type) {
  if (type === 0 && !ual.activeUser) {
    throw new Error('No active user found');
  }
  let name = ""
  if(type === 0)
  {
    name = ual.activeUser.accountName
  }
  else
  {
    name = account_name
  }
  const miningAssets = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'miningp',
    index_position: 1,
    key_type: 'name',
    upper_bound: name,
    lower_bound: name,
    limit: 1000
  };
  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(miningAssets);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(miningAssets);
    }
  return account.rows[0].total_mining;
}

export async function anchorGetMLCleanCashe(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'laundering',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000

  };
  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {

    return parseFloat(account.rows[0].total_laundered).toFixed(6);
  }
  else {
    return 0;
  }
}
export async function anchorGetTrapHouseUnlock(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'traphouse',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000

  };
  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if(account.rows.length !== 0)
  {
    return account.rows[0].unlocked;
  }
  else
  {
    return 0;
  }
}

export async function anchorGetLabUnlock(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'lab',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 1000

  };
  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if(account.rows.length !== 0)
  {
    return account.rows[0].unlocked;
  }
  else {
    return 0;
  }
}

export async function anchorGetuserinfors(ual, option) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var account
  if(ual.activeUser.client === undefined)
    {
      account = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      account = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (account.rows.length !== 0) {
    return account.rows[0][option];
  }
}

function sortarray(arr) {
  var arr2 = arr.map(function (o, i) { return { idx: i, obj: o }; }).sort(function (a, b) {
    return b.obj - a.obj;
  });
  for (var i = 0, j = arr2.length; i < j; i++) {
    arr[i] = arr2[i].idx + 1;
  }
  return arr;
}

export const getTimeCheckIn = async (ual) => {
  if (!ual.activeUser) {
    return ""
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'dailyreward',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var accountData
  if(ual.activeUser.client === undefined)
    {
      accountData = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      accountData = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (accountData.rows.length !== 0) {
    if (accountData.rows[0].username !== "none") {
      const data = []
      data.push({ day: accountData.rows[0].day, time: accountData.rows[0].time })
      return data;
    }
  }
  else {
    return 0;
  }
}

export const getUsername = async (ual) => {
  if (!ual.activeUser) {
    return ""
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var accountData
  if(ual.activeUser.client === undefined)
    {
      accountData = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      accountData = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (accountData.rows.length !== 0) {
    if (accountData.rows[0].username !== "none") {
      return accountData.rows[0].username;
    }
  }
  else {
    return ual.activeUser.accountName;
  }
}

// async function fetchWithExponentialBackoff(fetchFunction, retries = 3) {
//   for (let i = 0; i < retries; i++) {
//     try {
//       const result = await fetchFunction();
//       return result;
//     } catch (error) {
//       if (error.response && error.response.status === 429) {
//         // Retry after a delay, with exponential backoff
//         const delay = Math.pow(2, i) * 10; // Exponential backoff in milliseconds
//         await new Promise(resolve => setTimeout(resolve, delay));
//       } else {
//         // Handle other types of errors
//         throw error;
//       }
//     }
//   }
//   throw new Error('Exceeded maximum number of retries');
// }

export async function rankUsers(ual, rankingCriteria) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const avatarRequest = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'avatar',
    index_position: 1,
    key_type: 'name',
    lower_bound: null,
    upper_bound: null,
    limit: 2000
  };

  var avatarResponse
  if(ual.activeUser.client === undefined)
    {
      avatarResponse = await ual.activeUser.rpc.get_table_rows(avatarRequest);
    }
  else
    {
      avatarResponse = await ual.activeUser.client.v1.chain.get_table_rows(avatarRequest);
    }

  // Create a map to store avatar information for each user
  const avatarMap = new Map();

  if (avatarResponse.length !== 0) {
    avatarResponse.rows.forEach(row => {
      avatarMap.set(row.owner, row.image_id);
    });
  }

  const resultLists = [];

  for (let i = 0; i < rankingCriteria.length; i++) {
    const criteria = rankingCriteria[i];

    const criterias = {
      json: true,
      code: 'thehustlewax',
      scope: 'thehustlewax',
      table: criteria.table,
      index_position: 1,
      key_type: 'name',
      limit: 2000
    };

    var response
    if(ual.activeUser.client === undefined)
      {
        response = await ual.activeUser.rpc.get_table_rows(criterias);
      }
    else
      {
        response = await ual.activeUser.client.v1.chain.get_table_rows(criterias);
      }

    if (response.length !== 0) {
      let array = [];
      let result = [];

      for (let j = 0; j < response.rows.length; j++) {
        array.push(response.rows[j][criteria.rankKey]);
      }

      array = sortarray(array);

      for (let j = 0; j < array.length && j < criteria.limit; j++) {
        let _result = [];
        var accountName 
        if (typeof response.rows[array[j] - 1].username !== 'undefined' && response.rows[array[j] - 1].username !== 'none')
        {
          accountName = response.rows[array[j] - 1].username
        }
        else if(typeof response.rows[array[j] - 1].account !== 'undefined')
        {
          accountName = response.rows[array[j] - 1].account
        }
        else if(typeof response.rows[array[j] - 1].owner !== 'undefined')
        {
          accountName = response.rows[array[j] - 1].owner
        }

        _result.push(accountName);
        _result.push(parseFloat(response.rows[array[j] - 1][criteria.rankKey]));

        // Fetch image_id from the map
        var imageId 
        if (typeof response.rows[array[j] - 1].owner !== 'undefined')
        {
          imageId = avatarMap.get(response.rows[array[j] - 1].owner);
        }
        else if(typeof response.rows[array[j] - 1].account !== 'undefined')
        {
          imageId = avatarMap.get(response.rows[array[j] - 1].account);
        }
        if (imageId) {
          _result.push(imageId);
        }

        result.push(_result);
      }      
      resultLists.push(result);
    }
  }
  return resultLists;
}

export async function anchorGetConsignmentBoostersInfo(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignmentl',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.length !== 0) {
    var total = 0;
    const slots = []
    if (ownedNfts.rows.length !== 0) {
      if (ownedNfts.rows[0].enforcer_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].enforcer_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].gang_hire_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].gang_hire_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].skip_jail_time_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].skip_jail_time_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].production_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].production_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].chrono_capo_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].chrono_capo_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].like_a_boss_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].like_a_boss_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].og_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].og_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
    }
    return slots;
  }
}

export async function anchorGetLocationBoostersInfo(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'corner'
  }
  else if (location === 1) {
    table = 'traphouse'
  }
  else if (location === 2) {
    table = 'lab'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.length !== 0) {
    var total = 0;
    const slots = []
    if (ownedNfts.rows.length !== 0) {
      if (ownedNfts.rows[0].enforcer_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].enforcer_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].gang_hire_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].gang_hire_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].skip_jail_time_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].skip_jail_time_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].production_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].production_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].like_a_boss_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].like_a_boss_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
      if (ownedNfts.rows[0].og_booster > 0) {
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].og_booster;
        const response = await axios.get(url);
        const nft = response.data.data;
        slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img });
        total++;
      }
    }
    return slots;
  }
}

export async function anchorGetLocationNftInfo(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const time = Math.floor(Date.now() / 1000)

  var table = 'cornernft'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  const slots = []
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {
    var total = 0;

    for (let i = 0; i < ownedNfts.rows.length; i++) {
      var time_elapsed = time - ownedNfts.rows[i].last_claimed
      var cooldown = 0
      if (time_elapsed > SIX_HOURS) {
        time_elapsed = SIX_HOURS
      }

      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[i].asset_nft;
      const response = await axios.get(url);
      const nft = response.data.data;
      var level = 0;
      var shoot_out = -1;
      var stop_search = -1;

      if (nft.data !== undefined && nft.data.level !== undefined) {    
        level = nft.data.level;
      }

      if (nft.data.shootout !== undefined && nft.data.stopsearch !== undefined) {    
        shoot_out = nft.data.shootout;
        stop_search = nft.data.stopsearch;
      }

      const request2 = {
        json: true,
        code: 'thehustlewax',
        scope: 'thehustlewax',
        table: 'staked',
        index_position: 1,
        key_type: 'i64',
        upper_bound: parseInt(ownedNfts.rows[i].asset_nft),
        lower_bound: parseInt(ownedNfts.rows[i].asset_nft),
        limit: 1000
      };
    
      var stakedNfts
      if(ual.activeUser.client === undefined)
        {
          stakedNfts = await ual.activeUser.rpc.get_table_rows(request2);
        }
      else
        {
          stakedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request2);
        }

      if( stakedNfts.rows[0].cooldown > time )
      {
        cooldown = stakedNfts.rows[0].cooldown - time
      }

      const request1 = {
        json: true,
        code: 'thehustlewax',
        scope: 'thehustlewax',
        table: 'gangstats',
        index_position: 1,
        key_type: 'i32',
        upper_bound: response.data.data.template.template_id,
        lower_bound: response.data.data.template.template_id,
        limit: 10000
      };

      var template_data
      if(ual.activeUser.client === undefined)
        {
          template_data = await ual.activeUser.rpc.get_table_rows(request1);
        }
      else
        {
          template_data = await ual.activeUser.client.v1.chain.get_table_rows(request1);
        }
      var dirty_cash = 0

      if (template_data.rows.length !== 0) {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * time_elapsed
      }

      var seconds_in_jail = 0
      if (ownedNfts.rows[i].is_jailed === 1)
      {
        seconds_in_jail = ownedNfts.rows[i].jail_until - time      
      }
      slots.push({ id: total, image: "https://ipfs.io/ipfs/" + nft.data.img, isJailed: ownedNfts.rows[i].is_jailed, timeJailed: seconds_in_jail, dirtyCash: dirty_cash.toFixed(5), assetId:ownedNfts.rows[i].asset_nft, cooldown:cooldown, shoot_out:shoot_out, stop_search:stop_search, time_elapsed:time_elapsed});
      total++;
    }         
  }
  return slots; 
}

export async function anchorGetNftPopUpInfoLocation(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + asset_id;
  const response = await axios.get(url);
  const nft = response.data.data;

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'gangstats',
    index_position: 1,
    key_type: 'i32',
    upper_bound: response.data.data.template.template_id,
    lower_bound: response.data.data.template.template_id,
    limit: 10000
  };

  var strength = 0;
  var ambition = 0;
  var talent = "None"
  if( nft.data.Strength !== undefined)
  {
    strength = nft.data.Strength
  }

  if( nft.data.Ambition !== undefined)
  {
    ambition = nft.data.Ambition
  }

  if( nft.data.WarReady !== undefined)
  {
    talent = "War Ready"
  }

  if( nft.data.HeistReady !== undefined)
  {
    talent = "Heist Ready"
  }

  if( nft.data.StreetLegend !== undefined)
  {
    talent = "Street Legend"
  }
    
  var template_data
  if(ual.activeUser.client === undefined)
    {
      template_data = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      template_data = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }
  const power = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*nft.data.level*0.1))
  const information = []

  information.push({ name:nft.name, level:nft.data.level, rarity:nft.data.rarity, edition:nft.data.edition, type:nft.data.type, power:power.toFixed(7), ambition:ambition, strength:strength, talent:talent, max_level:template_data.rows[0].max_level})
  

  return information
}

export async function anchorGetNftPopUpJailUpgradeInfo(ual, asset_id, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const time = Math.floor(Date.now() / 1000)

  var table = 'cornernft'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }

  const request2 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'staked',
    index_position: 1,
    key_type: 'i64',
    upper_bound: parseInt(asset_id),
    lower_bound: parseInt(asset_id),
    limit: 1000
  };

  var stakedNfts
  if(ual.activeUser.client === undefined)
    {
      stakedNfts = await ual.activeUser.rpc.get_table_rows(request2);
    }
  else
    {
      stakedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request2);
    }
  var cooldown = 0;
  if (stakedNfts.rows[0].cooldown > time) {
    cooldown = stakedNfts.rows[0].cooldown - time;
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'i64',
    upper_bound: parseInt(asset_id),
    lower_bound: parseInt(asset_id),
    limit: 10000
  };
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  var jail_time = 0;
  if (ownedNfts.rows[0].jail_until > time) {
    jail_time = ownedNfts.rows[0].jail_until - time;
  }

  const information = []

  information.push({ cooldown: cooldown, isJailed: ownedNfts.rows[0].is_jailed, jailUntil: jail_time })

  return information
}

export async function anchorGetNftUpgradeCost(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + asset_id;
  const response = await axios.get(url);
  const nft = response.data.data;

  const request1 = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'gangstats',
    index_position: 1,
    key_type: 'i32',
    upper_bound: response.data.data.template.template_id,
    lower_bound: response.data.data.template.template_id,
    limit: 10000
  };

  var template_data
  if(ual.activeUser.client === undefined)
    {
      template_data = await ual.activeUser.rpc.get_table_rows(request1);
    }
  else
    {
      template_data = await ual.activeUser.client.v1.chain.get_table_rows(request1);
    }

  var dirty_cash = 0
  var clean_cash = 0 
  var hustler_token = 0
  var upgrade_token = 0
  var level = parseInt(nft.data.level) + 1

  var dirty_cash_skip = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*(level-1)*0.1)) * (parseFloat(template_data.rows[0].upgrd_cooldown) + (level-2)*6) * 1800
  var clean_cash_skip = dirty_cash_skip/3
  var hustler_token_skip = parseInt(template_data.rows[0].hustler_token_cd) + (level-2)*5

  if(nft.name === "Riley")
  {
      if(nft.data.rarity === "Common")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Rare")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Epic")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Shiny")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
  }
  else if(nft.name === "Minoto")
    {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
    }
  else if(nft.name === "Turan")
  {
      if(nft.data.rarity === "Common")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Rare")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Epic")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Shiny")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
  }
  else if(nft.name === "Bugz")
  {
      if(nft.data.rarity === "Common")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Rare")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Epic")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Shiny")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
  }
  else if(nft.name === "Professor K")
  {
      if(nft.data.rarity === "Common")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Rare")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Epic")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
      else if(nft.data.rarity === "Shiny")
      {
        dirty_cash = (parseFloat(template_data.rows[0].mining_capability) + parseFloat(template_data.rows[0].mining_capability*level*0.1)) * 86400 * 2.0
        clean_cash = parseFloat(dirty_cash/2)
        hustler_token = parseInt(template_data.rows[0].hustler_token_upgrd) + (level - 1) * 5
        upgrade_token = parseInt(template_data.rows[0].upgrd_token_upgrd)
      }
  }

  const information = []

  information.push({ dirtyCash:dirty_cash.toFixed(3), cleanCash:clean_cash.toFixed(3), hustlerToken:hustler_token.toFixed(3), upgradeToken:upgrade_token.toFixed(3), dirtyCashSkip:dirty_cash_skip.toFixed(3), cleanCashSkip:clean_cash_skip.toFixed(3), hustlerTokenSkip:hustler_token_skip.toFixed(3)})
  return information
}

export async function anchorGetConsignmentBoosters(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'consignmentl',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.rows.length !== 0) {
    var total = 0;
    if (ownedNfts.rows[0].enforcer_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].gang_hire_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].skip_jail_time_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].production_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].chrono_capo_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].like_a_boss_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].og_booster > 0) {
      total++;
    }
    return total;
  }
}

export async function anchorGetLocationBoosters(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'corner'
  if (location === 0) {
    table = 'corner'
  }
  else if (location === 1) {
    table = 'traphouse'
  }
  else if (location === 2) {
    table = 'lab'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length !== 0) {
    var total = 0;
    if (ownedNfts.rows[0].enforcer_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].gang_hire_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].skip_jail_time_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].production_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].like_a_boss_booster > 0) {
      total++;
    }
    if (ownedNfts.rows[0].og_booster > 0) {
      total++;
    }
    return total;
  }
}

export async function anchorGetLocationCrew(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  var table = 'cornernft'
  if (location === 0) {
    table = 'cornernft'
  }
  else if (location === 1) {
    table = 'traphousenft'
  }
  else if (location === 2) {
    table = 'labnft'
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: table,
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  return ownedNfts.rows.length
}

export async function anchorUpgradeConsignment(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdghost',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Upgrading Ghost failed:', e.message);
    throw e;
  }
}

export async function anchorSkipConsignmentUpgradeTime(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'bypassghost',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Upgrading Ghost failed:', e.message);
    throw e;
  }
}

export async function getUpgradeCostsForConsignmentBooster(ual, index) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'boostupgrd',
    index_position: 1,
    key_type: 'i32',
    upper_bound: parseInt(index + 1),
    lower_bound: parseInt(index + 1),
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows[0] !== undefined) {
    return ownedNfts.rows[0];
  }
  else {

    const upgradeSlotsCost = [];
    upgradeSlotsCost.push({
      clean_cash: 0,
      dirty_cash: 0,
      hustler_token: 0,
      slot_number: 0,
      upgrade_token: 0
    });
    return upgradeSlotsCost;
  }
}

export async function getUpgradeCostsForOtherBooster(ual, index) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'minebstupgrd',
    index_position: 1,
    key_type: 'i32',
    upper_bound: parseInt(index + 1),
    lower_bound: parseInt(index + 1),
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows[0] !== undefined) {
    return ownedNfts.rows[0];
  }
  else {

    const upgradeSlotsCost = [];
    upgradeSlotsCost.push({
      clean_cash: 0,
      dirty_cash: 0,
      hustler_token: 0,
      slot_number: 0,
      upgrade_token: 0
    });
    return upgradeSlotsCost;
  }
}

export async function getUpgradeCostsForOtherCrew(ual, index) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const count = Math.floor(index / 10) + 1;
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'minenftupgrd',
    index_position: 1,
    key_type: 'i32',
    upper_bound: count,
    lower_bound: count,
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows[0] !== undefined) {
    return ownedNfts.rows[0];
  }
  else {

    const upgradeSlotsCost = [];
    upgradeSlotsCost.push({
      clean_cash: 0,
      dirty_cash: 0,
      hustler_token: 0,
      slot_number: 0,
      upgrade_token: 0
    });
    return upgradeSlotsCost;
  }
}

export async function getUpgradeCostsForGhost(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + asset_id;
  const response = await axios.get(url);
  const nft = response.data.data;

  const level = parseInt(nft.data.level)
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'ghostupgrds',
    index_position: 1,
    key_type: 'i32',
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.rows[level] !== undefined) {
    const formattedData = {
      ...ownedNfts.rows[level],
      dirty_cash: parseFloat(ownedNfts.rows[level].dirty_cash).toFixed(2)
    };
    return formattedData;
  }
  else {
    const upgradeSlotsCost = [];
    upgradeSlotsCost.push({
      clean_cash: 0,
      dirty_cash: 0,
      hustler_token: 0,
      upgrade_token: 0,
      b_dirty_cash: 0,
      b_clean_cash: 0,
      b_hustlers_token: 0
    });
    return upgradeSlotsCost;
  }
}

export async function getSkipUpgradeCostsForGhost(ual, asset_id) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + asset_id;
  const response = await axios.get(url);
  const nft = response.data.data;

  var level1 = 0;

  if (nft.data !== undefined && nft.data.level !== undefined) {
    level1 = nft.data.level;
  }

  var level = parseInt(level1)
  if (level !== 0) {
    level -= 1;
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'ghostupgrds',
    index_position: 1,
    key_type: 'i32',
    limit: 10000
  };

  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }

  if (ownedNfts.rows[level] !== undefined) {
    const formattedData = {
      ...ownedNfts.rows[level],
      b_dirty_cash: parseFloat(ownedNfts.rows[level].b_dirty_cash).toFixed(2)
    };
    return formattedData;
  }
  else {
    const upgradeSlotsCost = [];
    upgradeSlotsCost.push({
      b_dirty_cash: 0,
      b_clean_cash: 0,
      b_hustlers_token: 0
    });
    return upgradeSlotsCost;
  }
}

export async function anchorAddBoosterSlotsConsignment(ual) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdcbslots',
      data: {
        user: ual.activeUser.accountName
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Upgrading booster slots in consignment failed:', e.message);
    throw e;
  }
}

export async function anchorAddBoosterSlotsHustle(ual, location) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdbstslts',
      data: {
        user: ual.activeUser.accountName,
        type: location
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Upgrading booster slots in consignment failed:', e.message);
    throw e;
  }
}

export async function anchorAddNftSlotsHustle(ual, location) {
  try {
    const action = {
      account: 'thehustlewax',
      name: 'upgrdnftslts',
      data: {
        user: ual.activeUser.accountName,
        type: location
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Upgrading booster slots in consignment failed:', e.message);
    throw e;
  }
}

export async function anchorGetUserResourcesFromWallet(ual) {
  try {
    const request = {
      json: true,
      code: 'hustletokens',
      scope: ual.activeUser.accountName,
      table: 'accounts',
      limit: 10,
      reverse: false,           // Optional: Get reversed data
      show_payer: false          // Optional: Show ram payer
    };

    var result
    if(ual.activeUser.client === undefined)
      {
        result = await ual.activeUser.rpc.get_table_rows(request);
      }
    else
      {
        result = await ual.activeUser.client.v1.chain.get_table_rows(request);
      }

    var tokenList = []
    result.rows.forEach((row) => {
      const balances = row.balance.split(' ')
      const tokenSymbol = balances[1]
      const tokenValue = parseFloat(balances[0])
      const token = {
        key: tokenSymbol,
        value: tokenValue
      }
      tokenList.push(token)
    })
    return tokenList
  }
  catch (e) {
    console.log('Upgrading booster slots in consignment failed:', e.message);
    throw e;
  }
}

export async function anchorDepositTokens(ual, assets) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }

  try {
    const action = {
      account: 'hustletokens',
      name: 'transfer1',
      data: {
        from: ual.activeUser.accountName,
        to: 'thehustlewax',
        quantity: assets,
        memo: 'exchange'
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Deposit failed:', e.message);
    throw e;
  }
}

export async function anchorWithdrawCleanCashHustlerToken(ual, assets) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'exchangetkn',
      data: {
        user: ual.activeUser.accountName,
        to_exchange: assets,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    console.log(ual)

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Withdraw failed:', e.message);
    throw e;
  }
}

export async function anchorExchangeCleanCashToHustler(ual, value) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'exchangeccht',
      data: {
        user: ual.activeUser.accountName,
        value: value,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Exchange failed:', e.message);
    throw e;
  }
}

export async function anchorAddImmunity(asset_id, ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'addimmunity',
      data: {
        user: ual.activeUser.accountName,
        asset_id: asset_id,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Paying bail failed:', e.message);
    throw e;
  }
}


export async function UnlockArea(ual, location) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  try {
    const action = {
      account: 'thehustlewax',
      name: 'unlockarea',
      data: {
        user: ual.activeUser.accountName,
        type: location,
      },
      authorization: [
        {
          actor: ual.activeUser.accountName,
          permission: ual.activeUser.requestPermission,
        },
      ],
    };

    const transaction = await ual.activeUser.signTransaction({
      actions: [action],
    }, { broadcast: true, blocksBehind: 3, expireSeconds: 60 });

    console.log(`Transaction broadcast! Id: ${transaction.transaction_id}`);
    return true;
  } catch (e) {
    console.log('Unlocking failed:', e.message);
    throw e;
  }
}

export async function isSafeBoosterStacked(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'safe',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let ownedNfts
  if(ual.activeUser.client === undefined)
  {
    ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
  }
else
  {
    ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
  }

  if (ownedNfts.rows.length === 0) {
    return ["", "", "", 1];
  }
  else {
    if(ownedNfts.rows[0].safe_small_boost > 0)
    {
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].safe_small_boost;
      const response = await axios.get(url);
      const nft = response.data.data;
      return ["https://ipfs.io/ipfs/" + nft.data.img, ownedNfts.rows[0].safe_small_boost, nft.data.description, 1.1];
    }   
    if(ownedNfts.rows[0].safe_medium_boost > 0)
    {
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].safe_medium_boost;
      const response = await axios.get(url);
      const nft = response.data.data;
      return ["https://ipfs.io/ipfs/" + nft.data.img, ownedNfts.rows[0].safe_medium_boost, nft.data.description, 1.3];
    }    
    if(ownedNfts.rows[0].safe_large_boost > 0)
    {
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].safe_large_boost;
      const response = await axios.get(url);
      const nft = response.data.data;
      return ["https://ipfs.io/ipfs/" + nft.data.img, ownedNfts.rows[0].safe_large_boost, nft.data.description, 2 ];
    }    
    if(ownedNfts.rows[0].safe_reset > 0)
    {
      const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[0].safe_reset;
      const response = await axios.get(url);
      const nft = response.data.data;
      return ["https://ipfs.io/ipfs/" + nft.data.img, ownedNfts.rows[0].safe_reset, nft.data.description, 1 ];
    }     
    return ["", "", "", 1];
  }
}

export async function getMiningPowerSafe(ual, rarity) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'saferarity',
    index_position: 1,    
    limit: 10000
  };

  let data
  if(ual.activeUser.client === undefined)
  {
    data = await ual.activeUser.rpc.get_table_rows(request);
  }
else
  {
    data = await ual.activeUser.client.v1.chain.get_table_rows(request);
  }

  if (data.rows.length === 0) {
    return [0,0,0,0,0];
  }
  else {
    for (let i = 0; i < data.rows.length; i++) {
      if(data.rows[i].rarity === rarity.toLowerCase())
      {        
        return[data.rows[i].hustler_token_s1, data.rows[i].hustler_token_s2, data.rows[i].hustler_token_s3, data.rows[i].min_main, data.rows[i].max_main]
      }
    }
    return [0,0,0,0,0];
  }
}

export async function isSafeStacked(ual, slot) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'safenfts',
    index_position: 2,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };

  let ownedNfts
  if(ual.activeUser.client === undefined)
  {
    ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
  }
else
  {
    ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
  }

  if (ownedNfts.rows.length === 0) {
    return ["", "", "", "", "", ""];
  }
  else {
    for (let i = 0; i < ownedNfts.rows.length; i++) {
      if(ownedNfts.rows[i].slot === slot)
      {        
        const url = `https://wax.api.atomicassets.io/atomicassets/v1/assets/` + ownedNfts.rows[i].asset_nft;
        const response = await axios.get(url);
        const nft = response.data.data;
        return ["https://ipfs.io/ipfs/" + nft.data.img, ownedNfts.rows[i].asset_nft, ownedNfts.rows[i].last_claimed, ownedNfts.rows[i].is_mining,
         ownedNfts.rows[i].phase, nft.data.maintenance, nft.data.rarity];
      }
    }
    return ["", "", "", "", "", ""];
  }
}

export async function isImmunityStaked(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var ownedNfts
  if(ual.activeUser.client === undefined)
    {
      ownedNfts = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      ownedNfts = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (ownedNfts.rows.length === 0) {
    return ["", ""];
  }
  else if (ownedNfts.rows[0].immunity_pass === 0) {
    return ["", ""];
  }
  else {
    const url = `https://wax.api.atomicassets.io/atomicassets/v1/templates/thehustlewax/` + ownedNfts.rows[0].immunity_pass;
    const response = await axios.get(url);
    const nft = response.data.data;

    return ["https://ipfs.io/ipfs/" + nft.immutable_data.img];
  }
}

export async function anchorGetFailedDrops(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var values
  if(ual.activeUser.client === undefined)
    {
      values = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      values = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (values.rows.length === 0) {
    return 0;
  }
  else {
    const counter = values.rows[0].times_failed_drop.reduce((accumulator, number) => {
      if (number > Math.floor(Date.now() / 1000)) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    return counter;
  }
}

export async function anchorGetUnlockedTraphouse(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'traphouse',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var values
  if(ual.activeUser.client === undefined)
    {
      values = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      values = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (values.rows.length === 0) {
    return 0;
  }
  else {
    return values.rows[0].unlocked;
  }
}

export async function anchorGetUnlockedLab(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'lab',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var values
  if(ual.activeUser.client === undefined)
    {
      values = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      values = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (values.rows.length === 0) {
    return 0;
  }
  else {
    return values.rows[0].unlocked;
  }
}

export async function anchorGetTotalDrops(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var values
  if(ual.activeUser.client === undefined)
    {
      values = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      values = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (values.rows.length === 0) {
    return 0;
  }
  else {
    const counter = values.rows[0].one_hundred_one_thousand.reduce((accumulator, number) => {
      if (number > Math.floor(Date.now() / 1000)) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    const counter1 = values.rows[0].one_thousand_infinity.reduce((accumulator, number) => {
      if (number > Math.floor(Date.now() / 1000)) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    const counter2 = values.rows[0].dirty_cash_token_cooldown.reduce((accumulator, number) => {
      if (number > Math.floor(Date.now() / 1000)) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    return counter + counter1 + counter2;
  }
}
export async function anchorGetHustlerTokenWithdraws(ual) {
  if (!ual.activeUser) {
    throw new Error('No active user found');
  }
  const request = {
    json: true,
    code: 'thehustlewax',
    scope: 'thehustlewax',
    table: 'accounts',
    index_position: 1,
    key_type: 'name',
    upper_bound: ual.activeUser.accountName,
    lower_bound: ual.activeUser.accountName,
    limit: 10000
  };
  var values
  if(ual.activeUser.client === undefined)
    {
      values = await ual.activeUser.rpc.get_table_rows(request);
    }
  else
    {
      values = await ual.activeUser.client.v1.chain.get_table_rows(request);
    }
  if (values.rows.length === 0) {
    return 0;
  }
  else {
    const counter = values.rows[0].hustler_token_cooldown.reduce((accumulator, number) => {
      if (number > Math.floor(Date.now() / 1000)) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    return counter;
  }
}

import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import {  anchorGetUserNfts, refreshAll, anchorAddAvatar } from "BlockchainInteractionWax";

const ProfileForm = ({ isOpen, setIsOpen, ual}) => {
  const [listData, setListData] = useState([]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  // use formatTime function to format remaining time

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser.accountName) {
        const data = await anchorGetUserNfts(ual);
        setListData(data);
      }
    };
    fetchData();
  }, [store]);  

  async function handleSetAvatar (image_id) {
    await anchorAddAvatar(ual, image_id)
    setIsOpen(false);
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525] py-4 px-6" style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">Add Profile Picture</h2>
            <FaTimes className="block text-2xl text-olive hover:cursor-pointer" onClick={() => setIsOpen(false)} />
          </div>
          <ul className="h-80 overflow-y-auto w-full">
            {listData.map((item) => (
              <li key={item.asset_id} className="flex items-center justify-between p-2 border-b-2 border-gray-500">
                <div className="relative flex items-center" style={{ width: '33.33%' }}>
                  <img src={`https://ipfs.io/ipfs/${item.img}`} alt="Decoded image" className="mr-2" style={{ width: "100%", height: "auto" }} />
                          
                </div>
                <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] flex h-24 w-full items-center justify-center rounded-xl" onClick={() => handleSetAvatar(item.img)} style={{ width: '33.33%' }}>
                  <FaPlus className="text-5xl text-gray-500" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ProfileForm;

import React from "react";
import ForeignExchange from "./foreignexchange";
import transfers from "../../assets/images/transfers.jpg";

const Transfers = ({ ual }) => {
    return (
      <div className="laundering-page">
        <div className="py-10 md:min-h-screen">
        <img src={transfers} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />
          <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}} >
            <div className="flex flex-col items-center">
              {/* <div className="img flex justify-center">
                <img
                  width="300"
                  height="200"
                  src={TransfersLogo}
                  alt="Transfers Logo"
                />
              </div> */}
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-y-24 md:flex-row md:items-start md:gap-x-24" style={{marginTop:"100px"}}>
              {/* <DropZone ual={ual}/> */}
              <ForeignExchange ual={ual}/>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Transfers;

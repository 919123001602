import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "assets/images/icons/solo-g.png";

import { getLocationStaked, anchorGetDataFromAssetIdNftLocation, anchorAddNftToHustle, refreshAll } from "BlockchainInteractionWax";

const AddCharacter = ({ isOpen, setIsOpen, ual }) => {
  const [listData, setListData] = useState([]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  // use formatTime function to format remaining time

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const data = await getLocationStaked(ual, 0);
        const nftData = await anchorGetDataFromAssetIdNftLocation(data, ual, 0)
        setListData(nftData);
      }
    };
    fetchData();
  }, [store, ual]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours}:${minutes % 60 < 10 ? `0${minutes % 60}` : minutes % 60}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  async function handleStake(asset_id) {
    await anchorAddNftToHustle(asset_id, ual, 0);
    await new Promise(resolve => setTimeout(resolve, 3000));
    await refreshAll(dispatch)
    setIsOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setListData(prevListData => prevListData.map(item => ({
        ...item,
        cooldown: item.cooldown > 0 ? item.cooldown - 1 : 0,
      })));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const rarityStyle = {
    position: "absolute",
    top: "12%", // Center the timer vertically
    left: "75%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  const levelStyle = {
    position: "absolute",
    top: "12%", // Center the timer vertically
    left: "25%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  const shootoutStyle = {
    position: "absolute",
    top: "75%", // Center the timer vertically
    left: "20%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  const stopSearchStyle = {
    position: "absolute",
    top: "75%", // Center the timer vertically
    left: "80%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525] py-4 px-6" style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">Add Corner Nft</h2>
            <FaTimes className="block text-2xl text-olive hover:cursor-pointer" onClick={() => setIsOpen(false)} />
          </div>
          <div>
            {listData.length === 0 && (
              <div className="h-60 flex flex-col w-full h-full items-center justify-center">
                <div className="mt-3 flex flex-col items-center justify-center gap-x-2">
                  <div className="w-20 gap-y-2 mb-4">
                    <img src={icon1} alt="Solo-G" className="object- h-full" />
                  </div>
                  <h1 className="px-5 py-2 text-center font-Montserrat text-md font-bold text-olive">
                    No characters in game inventory. <br />
                    Equip a character from the Stash.
                  </h1>
                </div>
              </div>
            )}
            {listData.length && (
              <ul className="h-80 overflow-y-auto w-full">
                {listData.map((item) => (
                  <li key={item.asset_id} className="flex items-center justify-between p-2 border-b-2 border-gray-500">
                    <div className="relative flex items-center" style={{ width: '33.33%' }}>
                      <img src={`https://ipfs.io/ipfs/${item.image}`} alt="Decoded image" className="mr-2" style={{ width: "100%", height: "auto" }} />

                  <div className="absolute inset-0 flex items-center justify-center" style={rarityStyle}>
                    <span className="text-white font-bold">
                      {item.rarity}
                    </span>
                  </div>

                  <div className="absolute inset-0 flex items-center justify-center" style={shootoutStyle}>
                    <span className="text-white font-bold">
                    PS: {item.shoot_out}
                    </span>
                  </div>

                  <div className="absolute inset-0 flex items-center justify-center" style={levelStyle}>
                    <span className="text-white  font-bold">
                    Level: {item.level}
                    </span>
                  </div>

                  <div className="absolute inset-0 flex items-center justify-center" style={stopSearchStyle}>
                    <span className="text-white font-bold">
                    SS: {item.stop_search}
                    </span>
                  </div>

                      {item.cooldown > 0 && (
                        <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
                          <span className="text-white text-xl font-bold">
                            {formatTime(item.cooldown)}
                          </span>
                        </div>
                      )}
                    </div>
                    <span style={{ color: "white", fontSize: "25px" }}>{item.name}</span>
                    <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] flex h-24 w-full items-center justify-center rounded-xl" onClick={() => handleStake(item.asset_id)} style={{ width: '33.33%' }}>
                      <FaPlus className="text-5xl text-gray-500" />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          
        </div>
      </Modal>
    </>
  );
};

export default AddCharacter;

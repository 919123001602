import React, { Component } from "react";

class Terms extends Component {
  render() {
    return (
      <div className="mx-auto w-2/3 py-10">
        <div className=" text-white opacity-90">
          <p className="text-center text-[30px] font-bold text-white max-md:pt-20 pb-3 max-md:leading-8">
            Terms and Conditions
          </p>
          <p className="py-2 text-center text-[15px] opacity-70">
            Last updated: July 29th 2024
          </p>
          <br />
          <p className="text-left ">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <br />
          <h1 className="pb-2 text-2xl font-semibold">
            Interpretation and Definitions
          </h1>
          <h2 className="pb-2 text-[18px] font-semibold">Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. <br />
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Definitions</h2>
          <p>
            For the purposes of these Terms and Conditions: <br />
            <br />
          </p>
          {/* <ul className="pl-6" style={{ listStyleType: 'disc' }}>
            <li>item 1</li>
            <li>item 2</li>
          </ul> */}
          <ul className="pl-6" style={{ listStyleType: "disc" }}>
            <li>
              <b>Application</b> means the software program provided by the
              Company downloaded by You on any electronic device.
            </li>
            <li>
              <b>Application Store</b> means the digital distribution service
              operated and developed by Apple Inc. (Apple App Store) or Google
              Inc. (Google Play Store) in which the Application has been
              downloaded.
            </li>
            <li>
              <b>Affiliate</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li>
              <b>Country</b> refers to: Birmingham, England Company (referred to
              as either "the Company", "We", "Us" or "Our" in this Agreement)
              refers to Nambua Games LTD.
            </li>
            <li>
              <b>Device</b> means any device that can access the Service such as
              a computer, a cell phone or a digital tablet.
            </li>
            <li>
              <b>Service</b> refers to the Application or the Website or both.
            </li>
            <li>
              <b>Terms and Conditions</b> (also referred as "Terms") mean these
              Terms and Conditions that form the entire agreement between You
              and the Company regarding the use of the Service. <br />
              This Terms and Conditions agreement has been created with the help
              of the TermsFeed Terms and Conditions Generator.
            </li>
            <li>
              <b>Third-party Service</b> means any services or content
              (including images, data, information, products or services)
              provided by a third-party that may be displayed, included or made
              available by the Service.
            </li>
            <li>
              <b>Website</b> refers to The Hustle, accessible from{" "}
              <a href="https://www.the-hustle.io">
                <span className="text-white hover:underline">
                  https://www.the-hustle.io
                </span>
              </a>{" "}
            </li>
            <li>
              <b>You</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </li>
          </ul>
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Acknowledgment</h2>
          <p>
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service. <br />
            <br />
            <ul className="pl-6" style={{ listStyleType: "disc" }}>
              <li>
                Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
              </li>
              <li>
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service.
              </li>
              <li>
                By accessing or using the Service You agree to be bound by these
                Terms and Conditions.
              </li>
              <li>
                If You disagree with any part of these Terms and Conditions then
                You may not access the Service.
              </li>
              <li>You represent that you are over the age of 17.</li>
              <li>
                The Company does not permit those under 17 to use the Service.
              </li>
              <li>
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company.
              </li>
            </ul>
            <br />
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. <br />
            Please read Our Privacy Policy carefully before using Our Service.{" "}
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Third Party Data Currently Used or Displayed in our website:
          </h2>
          <p>
            <ul className="pl-6" style={{ listStyleType: "disc" }}>
              <li>
                London Chasing Skylines Nightscape image by via{" "}
                <a href="https://www.peakpx.com/en/hd-wallpaper-desktop-koljw">
                  <span className="text-white hover:underline">
                    https://www.peakpx.com/en/hd-wallpaper-desktop-koljw
                  </span>
                </a>{" "}
              </li>
              <li>
                Abandoned blue house image by [Luis Müller] via{" "}
                <a href="https://unsplash.com/photos/t1IcKA8HkUM">
                  <span className="text-white hover:underline">
                    https://unsplash.com/photos/t1IcKA8HkUM
                  </span>
                </a>{" "}
              </li>
              <li>
                Hutlers Token image by [Boldurevaol] via{" "}
                <a href="https://www.dreamstime.com/boldurevaol_info">
                  <span className="text-white hover:underline">
                    https://www.dreamstime.com/boldurevaol_info
                  </span>
                </a>{" "}
              </li>
              <li>
                Clean Cash image by [Loopall] via{" "}
                <a href="https://www.dreamstime.com/loopall-latest-illustrations-vectors-clipart">
                  <span className="text-white hover:underline">
                    https://www.dreamstime.com/loopall-latest-illustrations-vectors-clipart
                  </span>
                </a>{" "}
              </li>
              <li>
                Upgrade Token image by [Mariya Nesterova] via{" "}
                <a href="https://www.alamy.com/stock-photo/?name=Mariya+Nesterova&pseudoid=FBC6EF73-675D-4A3F-B6A9-24ED37695BBF&sortBy=relevant">
                  <span className="text-white hover:underline">
                    https://www.alamy.com/stock-photo/?name=Mariya+Nesterova&pseudoid=FBC6EF73-675D-4A3F-B6A9-24ED37695BBF&sortBy=relevant
                  </span>
                </a>{" "}
              </li>
              <li>
                Dirty Cash by [Bronx] via [Deviant Art] London streets night by
                [Edo Zollo] via{" "}
                <a href="https://www.huckmag.com/art-and-culture/edo-zollo-photography-london-at-night/">
                  <span className="text-white hover:underline">
                    https://www.huckmag.com/art-and-culture/edo-zollo-photography-london-at-night/
                  </span>
                </a>{" "}
              </li>
              <li>
                London streets night by [Edo Zollo] via{" "}
                <a href="https://www.shutterstock.com/g/gorodenkoff">
                  <span className="text-white hover:underline">
                    https://www.huckmag.com/art-and-culture/edo-zollo-photography-london-at-night/
                  </span>
                </a>{" "}
              </li>
              <li>
                Cladestine Lab by [Gorodenkoff] via{" "}
                <a href="https://www.shutterstock.com/g/gorodenkoff">
                  <span className="text-white hover:underline">
                    https://www.shutterstock.com/g/gorodenkoff
                  </span>
                </a>{" "}
              </li>
            </ul>
          </p>
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Links to Other Websites
          </h2>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company. <br />
            <br />
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. <br />
            You further acknowledge and agree that the Company shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods or services available on
            or through any such web sites or services. <br />
            <br />
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.{" "}
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Termination</h2>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you abuse the game system (this can be anything that
            offers an unfair advantage to other players) community or breach
            these Terms and Conditions. <br />
            <br />
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Fair Play Rules
          </h2>
          <p>
          We are trying to build a game that will survive the test of time. To achieve this,
           we all need to have some degree of trust that the game is a fair one. Violations of
            the spirit of the game diminish trust and overall stability of The Hustle. <br />
            <br />
            Our aim is to build a sustainable, player-driven economy. Behaviours that excessively
             extract resources undermine the game's long-term health. <br />
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Termination</h2>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you abuse the game system (this can be anything that
            offers an unfair advantage to other players) community or breach
            these Terms and Conditions. <br />
            <br />
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>{" "}
          <br />
          <p>
          <h2 className="pb-2 text-[18px] font-semibold">Bots</h2>
          <br />
          Unacceptable: Onchain Bots. In web3 these use public-facing smart contract calls and APIs
           to interact with components of the game. We acknowledge their existence and take measures
            to ensure they don't disrupt the game's fairness and stability. <br />
            <br />
            Unacceptable: Gameplay Bots. These automate traditional games and go against our priority
             of creating a fair game experience for humans. Therefore, we do not allow their use, and
              players caught using them may face penalties or account banning. <br />
              In both cases, any bot violating the terms of service will be subject to the same 
              consequences as human players.  <br /> <br />
              <h2 className="pb-2 text-[18px] font-semibold">Multi Account</h2>
              <br />
              Acceptable: Creating multiple accounts for entertainment purposes and playing within the
            defined rules of the game. This may be done to challenge oneself or simply experience
            different gameplay dynamics. As long as accounts follow the spirit of fair play, this
              enhances diversity of experience.  <br /> <br />
              Unacceptable: Systematically creating multiple accounts to accumulate excessive resources
              or manipulate markets to profit. This damages The Hustle economy by disproportionately
                extracting assets. Intentionally undermining confidence in the fairness and
                sustainability of the game's systems diminishes trust.  <br /> <br />
                We reserve the right to investigate accounts believed to primarily extract resources
                without meaningfully participating. Factors considered include resource acquisition rate,
                  transfers off platform, and exchange sales. Penalties will scale with the degree of
                  coordinated exploitation detected through investigations.  <br /> <br />
                  <h2 className="pb-2 text-[18px] font-semibold">Enforcement</h2>
                  <br />
                  If investigation determines systematic exploitation, penalties may apply even if specific
                   behaviours were not previously explicit. Extraction harms the game's overall economy over time.
                   <br />
            Those intentionally undermining the closed beta economy may face penalties including event rewards
             ineligibility, deprioritized game actions, unsubsidized transaction fees, and account banning.
              <br />
              We will acknowledge players who responsibly disclose exploits.
              <br />
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Termination</h2>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you abuse the game system (this can be anything that
            offers an unfair advantage to other players) community or breach
            these Terms and Conditions. <br />
            <br />
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Limitation of Liability
          </h2>
          <p>
          Notwithstanding any damages that you might incur, the Company and any
          of its suppliers shall not be liable for any damages arising from the
          game not working for you or any other fault. Your use of the Service is 
          at your own risk, and the Company and its suppliers shall have no liability 
          to you under any provision of these Terms. This limitation of liability 
          includes, but is not limited to, any damages arising from market conditions 
          or factors beyond the Company's control. If you have not made any purchases 
          through the Service, the Company and its suppliers shall have no liability 
          to you. <br />
            <br />
            To the maximum extent permitted by applicable law, in no event shall the 
            Company or its suppliers be liable for any special, incidental, indirect, 
            or consequential damages whatsoever (including, but not limited to, damages 
            for loss of profits, loss of data or other information, for business 
            interruption, for personal injury, loss of privacy arising out of or in any 
            way related to the use of or inability to use the Service, third-party software 
            and/or third-party hardware used with the Service, or otherwise in connection 
            with any provision of this Terms), even if the Company or any supplier has 
            been advised of the possibility of such damages and even if the remedy fails 
            of its essential purpose. <br />
            <br />
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. <br />
            In these states, each party's liability will be limited to the
            greatest extent permitted by law.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            "AS IS" and "AS AVAILABLE" Disclaimer
          </h2>
          <p>
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, the Company, on its own
            behalf and on behalf of its Affiliates and its and their respective
            licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to
            the Service, including all implied warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            warranties that may arise out of course of dealing, course of
            performance, usage or trade practice. <br />
            <br />
            Without limitation to the foregoing, the Company provides no
            warranty or undertaking, and makes no representation of any kind
            that the Service will meet Your requirements, achieve any intended
            results, be compatible or work with any other software,
            applications, systems or services, operate without interruption,
            meet any performance or reliability standards or be error free or
            that any errors or defects can or will be corrected. <br />
            <br />
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied:
          </p>
          <p>
            <br />
            <ul className="pl-6" style={{ listStyleType: "disc" }}>
              <li>
                as to the operation or availability of the Service, or the
                information, content, and materials or products included thereon
              </li>
              <li>that the Service will be uninterrupted or error-free</li>
              <li>
                as to the accuracy, reliability, or currency of any information
                or content provided through the Service or
              </li>
              <li>
                that the Service, its servers, the content, or e-mails sent from
                or on behalf of the Company are free of viruses, scripts, trojan
                horses, worms, malware, timebombs or other harmful components.
              </li>
            </ul>
          </p>
          <br />
          <p>
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. <br />
            But in such a case the exclusions and limitations set forth in this
            section shall be applied to the greatest extent enforceable under
            applicable law. <br />
            <br />
          </p>
          <h2 className="pb-2 text-[18px] font-semibold">Governing Law</h2>
          <p>
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. <br />
            Your use of the Application may also be subject to other local,
            state, national, or international laws.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Disputes Resolution
          </h2>
          <p>
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            For European Union (EU) Users
          </h2>
          <p>
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            United States Legal Compliance
          </h2>
          <p>You represent and warrant that</p> <br />
          <p>
            <ul className="pl-6" style={{ listStyleType: "disc" }}>
              <li>
                You are not located in a country that is subject to the United
                States government embargo, or that has been designated by the
                United States government as a "terrorist supporting" country,
                and
              </li>
              <li>
                You are not listed on any United States government list of
                prohibited or restricted parties.
              </li>
            </ul>
          </p>
          <br />
          <h1 className="pb-2 text-2xl font-semibold">
            Severability and Waiver
          </h1>
          <h2 className="pb-2 text-[18px] font-semibold">Severability</h2>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.{" "}
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Waiver</h2>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            affect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Translation Interpretation
          </h2>
          <p>
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">
            Changes to These Terms and Conditions
          </h2>
          <p>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. <br />
            If a revision is material we will make reasonable efforts to provide
            at least 30 days' notice prior to any new terms taking effect. What
            constitutes a material change will be determined at Our sole
            discretion. <br />
            <br />
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. <br />
            If You do not agree to the new terms, in whole or in part, please
            stop using the website and the Service.
          </p>{" "}
          <br />
          <h2 className="pb-2 text-[18px] font-semibold">Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
        </div>
      </div>
    );
  }
}

export default Terms;

import React, { useState, useEffect, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import { useSelector } from "react-redux";

import { anchortGetNftInformation, getConsignmentStats } from "BlockchainInteractionWax.js"

const Stats = ({ isOpen, setIsOpen, ual, assetId, item }) => {


  const [nftData, setNftData] = useState([{ name: "Default Name", level: 0, rarity: "Unknown", type: "Unknown", edition: "Unknown" }]);
  const [miningPower, setMiningPower] = useState(0)

  const store = useSelector((state) => state);
  const fetchData = useCallback(async () => {
    if (ual.activeUser) {
      try {
        const value = await anchortGetNftInformation(ual, assetId)
        setNftData(value)
        const value1 = await getConsignmentStats(ual, assetId)
        setMiningPower(value1)

      } catch (error) {
        console.log("errir", error)
      }
      const value = await anchortGetNftInformation(ual, assetId)
      setNftData(value)
      const value1 = await getConsignmentStats(ual, assetId)
      setMiningPower(value1)
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [store]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div
        className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525]  py-4 px-6 "
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <div>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">
              {item.schema === "boosts" || nftData[0].type === "Boost" ? 'Boost Info' : item.schema === "passes" || 
                nftData[0].type === "Pass" || item.schema === "safes" ? "Pass Info" : item.schema === "packs" ? "Packs Info" : item.schema === "trophies" ? 'Trophy Info' :'Character Stats' }
            </h2>
            <FaTimes
              className="block text-2xl text-olive hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>


          <div>
            <div className="mt-2">
              {item.schema === "passes" || nftData[0].type === "Pass" ? (
                <>
                  <p className="text-white">{item.description ?? "N/A"}</p>
                  <br />
                  <Item text={"Name"} value={nftData[0].name ?? "N/A"} />
                  <Item text={"Rarity"} value={nftData[0].rarity ?? "N/A"} />
                  <Item text={"Type"} value={nftData[0].type ?? "N/A"} />
                </>
              ) :
                item.schema === "boosts" || nftData[0].type === "Boost" ? (
                  <>
                    <p className="text-white">{item.description ?? "N/A"}</p>
                    <br />
                    <Item text={"Name"} value={nftData[0].name ?? "N/A"} />
                    <Item text={"Rarity"} value={nftData[0].rarity ?? "N/A"} />
                    <Item text={"Type"} value={nftData[0].type ?? "N/A"} />
                  </>
                ) :
                  item.schema === "packs" ? (
                    <>
                      <p className="text-white">{item.description ?? "N/A"}</p>
                      <br />
                      <Item text={"Name"} value={nftData[0].name ?? "N/A"} />
                      {/* <Item text={"Rarity"} value={nftData[0].rarity ?? "N/A"} /> */}
                      <Item text={"Type"} value={nftData[0].type ?? "N/A"} />
                    </>
                  ) : (
                    <>

                      <p className="text-white">{item.description ?? "N/A"}</p>
                      <br />
                      <Item text={"Name"} value={nftData[0].name ?? "N/A"} />
                      <Item text={"Rarity"} value={nftData[0].rarity ?? "N/A"} />
                      <Item text={"Level"} value={nftData[0].level ?? "N/A"} />
                      {/*<Item text={"Mining Power/h"} value={miningPower ?? "N/A"} /> TO DO FIX*/} 

                    </>
                  )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Item = ({ text, value }) => (
  <p className="text-white">
    <span className="text-white ">{text}:</span> {"  "} {value}
  </p>
);

export default Stats;

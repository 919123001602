import React from "react";
import { Link } from "react-router-dom"; // Import Link
import logoImage from "assets/images/logo.png";

const Logo = () => {
  return (
    <div className="w-28">
      {/* Wrap the img tag with Link component */}
      <Link to="/">
        <img src={logoImage} alt="Logo" className="h-full w-full object-cover" />
      </Link>
    </div>
  );
};

export default Logo;
import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { anchorGetGRank } from "BlockchainInteractionWax";
import Safe from "components/SafeHouse/Safe/Safe";
import Booster from "components/SafeHouse/Safe/Booster";
import Safe2 from "components/SafeHouse/Safe/Safe2";
import Safehouse from "assets/images/safehouse.jpg";
import SafehouseNot from "assets/images/safe_not_enough_g.jpg";
import { useDispatch, useSelector } from "react-redux";

const SafeHouse = ({ ual }) => {

  const [gRank, setGRank] = useState(0)

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const rank = await anchorGetGRank(ual)
        setGRank(rank)
      }
    };
    fetchData();
  }, [store, ual.activeUser]);

  return (
    <div className="py-10 min-h-screen">   
      {gRank >= 100 && (     
      <div className="flex flex-col gap-y-5 px-4 md:grid md:grid-cols-5 md:gap-x-5 md:px-10" >
        <img src={Safehouse} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.3', zIndex: "0" }} />
        
        {/* First row is empty for spacing in small screens */}
        <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}}>
        <div className="md:col-span-1" ></div> {/* Becomes first column for space on larger screens */}
        </div>
        
        {/* Second row contains the first Safe component, takes full width on small screens */}
        <Safe ual={ual} className="col-span-1" /> {/* Takes the second column on larger screens */}

        {/* Third row is empty for spacing in small screens */}
        <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}}>
        <Booster ual={ual} className="col-span-1" /> {/* Takes the second column on larger screens */}
        </div>

        {/* Fourth row contains the second Safe component, takes full width on small screens */}
        <div className="container mx-auto " style={{ backgroundSize:"cover", position:"relative"}}>
        <Safe2 ual={ual} className="col-span-1" /> {/* Takes the fourth column on larger screens */}
        </div>

        {/* Fifth row is empty for spacing in small screens */}
        <div className="md:col-span-1"></div> {/* Becomes fifth column for space on larger screens */}
      </div>
      )}
      {gRank < 100 && (
        <div className="flex flex-col gap-y-5 px-4 md:grid md:grid-cols-5 md:gap-x-5 md:px-10" >
        <img src={SafehouseNot} style={{ position: 'fixed', top: 0, left: 0, width: "100%", height: "100vh", opacity: '0.5', zIndex: "0" }} />
        </div>
      )}
    </div>
  );
};

export default SafeHouse;

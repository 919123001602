import React from "react";
import logoutIcon from "assets/images/icons/logout.png";
import userIcon from "assets/images/icons/usericon.png";

const AuthMenu = ({ ual }) => {

  const openLoginModal = () => {
    if (!ual.activeUser) {
      ual.showModal();
    }
  };

  return (
    <div className={`bg-transparent py-2 px-8 text-lime-400 hover:bg-[#252525] focus:bg-[#252525] bg-transparent hover:bg-[#252525] focus:bg-[#252525]`}>
      {
        !ual.activeUser ? 
        <button
          className="w-8 cursor-pointer"
          type="button"
          onClick={openLoginModal}
        >
          <img src={userIcon} className="h-full w-full" alt="user" />
        </button>
      : <button
        className="w-6 cursor-pointer"
        onClick={ual.logout}
      >
        <img src={logoutIcon} className="h-full w-full" alt={ual.activeUser.accountName} />
      </button>
      }
    </div>
  );
};

export default AuthMenu;
